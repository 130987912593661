import React, { useState, useEffect } from "react";
import { Flex, Icon, Card, Text } from "@chakra-ui/react";
import { getUserChats, getChatMessages, sendMessage, subscribeToChatEvents } from "contexts/ChatContext";
import ChatList from "./ChatList";
import MessageBox from "./MessageBox";
import MessageInput from "./MessageInput";
import IconBox from "components/icons/IconBox";
import { MdChat } from "react-icons/md";

const ChatWindow = ({ userId, userName }) => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [eventSource, setEventSource] = useState(null); // Хранение SSE подключения

  // Получение списка чатов и автоматическое открытие верхнего чата
  useEffect(() => {
    const fetchChats = async () => {
      try {
        const userChats = await getUserChats(userId);
  
        // Сортируем чаты по нужному критерию
        const sortedChats = userChats.sort((a, b) => {
          // Пример: сортировка по убыванию времени последнего сообщения
          return new Date(b.lastMessageTime) - new Date(a.lastMessageTime);
        });
  
        setChats(sortedChats);
  
        // Устанавливаем верхний чат как выбранный
        if (sortedChats.length > 0) {
          setSelectedChat(sortedChats[0]);
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };
  
    fetchChats();
  }, [userId]);

  // Обработка выбранного чата и подключение SSE
  useEffect(() => {
    if (selectedChat) {
      const fetchMessages = async () => {
        try {
          const chatMessages = await getChatMessages(selectedChat.id);
          setMessages(chatMessages);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      };
      fetchMessages();

      // Настройка SSE
      const source = subscribeToChatEvents(
        selectedChat.id,
        (newMessage) => {
          // Обработка нового сообщения
          setMessages((prev) => [...prev, newMessage]);
        },
        (error) => {
          console.error("Error in SSE:", error);
        }
      );

      setEventSource(source); // Сохраняем подключение SSE

      return () => {
        if (source) {
          source.close(); // Закрываем SSE при смене чата
        }
      };
    }
  }, [selectedChat]);

  // Отправка сообщений
  const handleSend = async (formData) => {

    try {
      const messageData = { ...formData, user_name: userName };
      await sendMessage(selectedChat.id, messageData);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <>
      <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px">
        <IconBox
          w="56px"
          h="56px"
          bg="white"
          float="left"
          icon={<Icon w="32px" h="32px" as={MdChat} color="navy.500" />}
        />
        <Text pl="20px" fontSize="30px" fontWeight="bold" lineHeight="140%">
          Чаты
        </Text>
      </Flex>
      <Card w="100%" p={{ base: "20px", md: "40px" }}>
        <Flex direction="row">
          <Flex flex="1" direction="column">
            {selectedChat && <MessageBox userId={userId} messages={messages} />}
            {selectedChat && <MessageInput userId={userId} onSend={handleSend} />}
          </Flex>
          <ChatList chats={chats} onSelectChat={setSelectedChat} />
        </Flex>
      </Card>
    </>
  );
};

export default ChatWindow;
