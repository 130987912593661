import { Button, Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState } from "react";
import Modal from "./EditModal";

export default function CompanyInfoCard({ user, sendOrganizationData }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editedValues, setEditedValues] = useState({
    userID: user.client_id,
    INN: "",
    BIC: "",
    website: "",
  });

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (field, value) => {
    setEditedValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const result = await sendOrganizationData({
        inn: editedValues.INN,
        bic: editedValues.BIC,
        website: editedValues.website,
      });
      closeModal();
      console.log("Результат:", result);
    } catch (error) {

      
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="left">
      <Flex justifyContent="space-between" alignItems="center" mt="10px" mb="24px">
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
          Информация об организации
        </Text>
        <Button
          onClick={openModal}
          fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          colorScheme="blue"
        >
          Редактировать
        </Button>
      </Flex>

      <SimpleGrid columns="3"  gridTemplateRows="repeat(8, 1fr)" gridAutoFlow="column dense" spacingY="10px" spacingX="40px">
        {[
          ["Полное наименование:", "full_company_name"],
          ["Краткое наименование:", "short_company_name"],
          ["ИНН:", "inn"],
          ["КПП:", "kpp"],
          ["ОГРН:", "ogrn"],
          ["Адрес местонахождения:", "address"],
          ["Вебсайт:", "website"],
          ["ФИО единоличного исполнительного органа:", "executive_name"],
          ["Должность руководителя:", "executive_position"],
          ["Основной и дополнительные ОКВЭД:", "okved"],
          ["ФИО учредителей:", "founders"],
          ["ОКПО:", "okpo"],
          ["ПФР:", "pfr"],
          ["ФСС:", "fss"],
          ["ОКАТО:", "okato"],
          ["ОКТМО:", "oktmo"],
          ["ОКОПФ:", "okopf"],
          ["ОКОГУ:", "okogu"],
          ["ОКФС:", "okfs"],
          ["Название банка:", "bank_name"],
          ["БИК:", "bic"],
          ["Корр. счёт:", "correspondent_account"],
          ["Расчётный счёт:", "account_number"],
          ["Дата открытия счёта:", "account_opening_date"],
        ].map(([label, field]) => (
          <Flex key={field} direction="column">
            <Text color={textColorSecondary} fontSize="md">{label}</Text>
            <Text color={textColorPrimary} fontSize="md">
              {Array.isArray(user[field])
                ? user[field].join(", ")
                : user[field] || "---"}
            </Text>
          </Flex>
        ))}
      </SimpleGrid>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          editedValues={editedValues}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      )}
    </Card>
  );
}
