import React from "react";
import { Box, List, ListItem, Text } from "@chakra-ui/react";

const ChatList = ({ chats, onSelectChat }) => {
  // Функция форматирования даты
  const formatDate = (dateString) => {
    if (!dateString) return "—"; // Если даты нет
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  // Сортируем чаты по дате (lastMessageTime или created_at)
  const sortedChats = [...chats].sort((a, b) => {
    const dateA = new Date(a.lastMessageTime || a.created_at);
    const dateB = new Date(b.lastMessageTime || b.created_at);
    return dateB - dateA; // Убывание: более новые сверху
  });

  return (
    <Box bg="gray.50" p={4} h="100%" w="300px" overflowY="auto">
      <List spacing={3}>
        {sortedChats.map((chat) => (
          <ListItem
            key={chat.id}
            bg="white"
            p={3}
            borderRadius="md"
            boxShadow="sm"
            _hover={{ bg: "gray.100", cursor: "pointer" }}
            onClick={() => onSelectChat(chat)}
          >
            <Text fontWeight="bold">{chat.title || `Chat ${chat.id}`}</Text>
            {/* Отображаем дату */}
            <Text fontSize="sm" color="gray.500">
              {formatDate(chat.lastMessageTime || chat.created_at)}
            </Text>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ChatList;
