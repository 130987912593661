import React, { useState, useEffect, useContext } from "react";
import { Flex, Spinner, Card, Text } from "@chakra-ui/react";
import { fetchChatByOrderId, getChatMessages, sendMessage, subscribeToChatEvents } from "contexts/ChatContext";
import MessageBox from "views/admin/profile/components/MessageBox";
import MessageInput from "views/admin/profile/components/MessageInput";
import { UserContext } from "contexts/UserContext";

const ChatWindow = ({ orderId }) => {
  const { user } = useContext(UserContext);
  const userId = user.client_id
  const [chat, setChat] = useState(null); // Информация о чате
  const [messages, setMessages] = useState([]); // Сообщения
  const [loading, setLoading] = useState(true); // Состояние загрузки
  const [error, setError] = useState(null); // Ошибка при загрузке чата
  const [eventSource, setEventSource] = useState(null); // SSE подключение

  // Загрузка чата по `orderId`
  useEffect(() => {
    const fetchChat = async () => {
      try {
        setLoading(true);
        setError(null);
        const chatData = await fetchChatByOrderId(orderId); // Используем fetchChatByOrderId
        if (!chatData) {
          setError("Чат не найден.");
          setChat(null);
        } else {
          setChat(chatData);
          const messagesData = await getChatMessages(chatData.id); // Загружаем сообщения
          setMessages(messagesData);
        }
      } catch (err) {
        setError("Ошибка при загрузке чата.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchChat();
  }, [orderId]);

  // Подключение SSE для получения новых сообщений
  useEffect(() => {
    console.log(chat);
    if (chat) {
      const source = subscribeToChatEvents(
      
        chat.id,
        (newMessage) => {
          setMessages((prev) => [...prev, newMessage]); // Добавляем новое сообщение
        },
        (error) => {
          console.error("Ошибка подключения SSE:", error);
          setError("Проблема с подключением к чату.");
        }
      );

      setEventSource(source);

      return () => {
        if (source) {
          source.close(); // Закрываем SSE при размонтировании
        }
      };
    }
  }, [chat]);

  // Отправка сообщений
  const handleSend = async (formData) => {
    try {
      const messageData = { ...formData, user_name: user.name };
      await sendMessage(chat.id, messageData);
    } catch (error) {
      console.error("Ошибка отправки сообщения:", error);
    }
  };
  

  // Отображение состояния загрузки
  if (loading) {
    return (
      <Flex align="center" justify="center" h="100vh">
        <Spinner size="lg" color="blue.500" />
      </Flex>
    );
  }

  // Отображение ошибки или отсутствие чата
  if (!chat || error) {
    return (
      <Flex align="center" justify="center" h="100vh">
        <Text fontSize="lg" color="gray.500">
          {error || "Чат для этой заявки не найден."}
        </Text>
      </Flex>
    );
  }

  // Основное отображение чата
  return (
    <Card w="100%" p={{ base: "20px", md: "40px" }}>
      <Flex direction="column" h="100%">
        <MessageBox userId={userId} messages={messages} />
        <MessageInput userId={userId} onSend={handleSend} />
      </Flex>
    </Card>
  );
};

export default ChatWindow;
