import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import RequestModal from "components/newOrder/NewOrder.js";
import { MdBarChart, MdPerson, MdDescription, MdHandshake } from "react-icons/md"; // Пример иконок
import { MdChat } from "react-icons/md"; // Иконка чата
import { UserContext } from "contexts/UserContext";

export function SidebarLinks() {
  // Chakra color mode
  let location = useLocation();
  const { user } = useContext(UserContext);
  let activeColor = useColorModeValue("gray.700", "white");
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  

  // Проверяем, является ли текущий путь активным
  const activeRoute = (routePath) => {
    return location.pathname === routePath;
  };

  return (
    <>
      {/* Пример вручную созданных ссылок */}
      <NavLink to="/admin/default">
        <HStack
          spacing={activeRoute("/admin/default") ? "22px" : "26px"}
          py="5px"
          ps="10px"
        >
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box color={activeRoute("/admin/default") ? activeIcon : textColor} me="18px">
              <MdBarChart />
            </Box>
            <Text
              me="auto"
              color={activeRoute("/admin/default") ? activeColor : textColor}
              fontWeight={activeRoute("/admin/default") ? "bold" : "normal"}
            >
              Аналитика
            </Text>
          </Flex>
          <Box
            h="36px"
            w="4px"
            bg={activeRoute("/admin/default") ? brandColor : "transparent"}
            borderRadius="5px"
          />
        </HStack>
      </NavLink>

      <NavLink to="/admin/orders">
        <HStack
          spacing={activeRoute("/admin/orders") ? "22px" : "26px"}
          py="5px"
          ps="10px"
        >
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box color={activeRoute("/admin/orders") ? activeIcon : textColor} me="18px">
              <MdDescription />
            </Box>
            <Text
              me="auto"
              color={activeRoute("/admin/orders") ? activeColor : textColor}
              fontWeight={activeRoute("/admin/orders") ? "bold" : "normal"}
            >
              Мои заявки
            </Text>
          </Flex>
          <Box
            h="36px"
            w="4px"
            bg={activeRoute("/admin/orders") ? brandColor : "transparent"}
            borderRadius="5px"
          />
        </HStack>
      </NavLink>

      <NavLink to="/admin/profile">
        <HStack
          spacing={activeRoute("/admin/profile") ? "22px" : "26px"}
          py="5px"
          ps="10px"
        >
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box color={activeRoute("/admin/profile") ? activeIcon : textColor} me="18px">
              <MdPerson />
            </Box>
            <Text
              me="auto"
              color={activeRoute("/admin/profile") ? activeColor : textColor}
              fontWeight={activeRoute("/admin/profile") ? "bold" : "normal"}
            >
              Личный кабинет
            </Text>
          </Flex>
          <Box
            h="36px"
            w="4px"
            bg={activeRoute("/admin/profile") ? brandColor : "transparent"}
            borderRadius="5px"
          />
        </HStack>
      </NavLink>
      {user?.client_type == "agent" && (
      <NavLink to="/admin/organizations">
        <HStack
          spacing={activeRoute("/admin/organizations") ? "22px" : "26px"}
          py="5px"
          ps="10px"
        >
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box color={activeRoute("/admin/organizations") ? activeIcon : textColor} me="18px">
              <MdHandshake />
            </Box>
            <Text
              me="auto"
              color={activeRoute("/admin/organizations") ? activeColor : textColor}
              fontWeight={activeRoute("/admin/organizations") ? "bold" : "normal"}
            >
              Организации
            </Text>
          </Flex>
          <Box
            h="36px"
            w="4px"
            bg={activeRoute("/admin/organizations") ? brandColor : "transparent"}
            borderRadius="5px"
          />
        </HStack>
      </NavLink>
  )}
      {/* Новая кнопка чата */}
      <NavLink to="/admin/chat">
        <HStack
          spacing={activeRoute("/admin/chat") ? "22px" : "26px"}
          py="5px"
          ps="10px"
        >
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box color={activeRoute("/admin/chat") ? activeIcon : textColor} me="18px">
              <MdChat />
            </Box>
            <Text
              me="auto"
              color={activeRoute("/admin/chat") ? activeColor : textColor}
              fontWeight={activeRoute("/admin/chat") ? "bold" : "normal"}
            >
              Сообщения
            </Text>
          </Flex>
          <Box
            h="36px"
            w="4px"
            bg={activeRoute("/admin/chat") ? brandColor : "transparent"}
            borderRadius="5px"
          />
        </HStack>
      </NavLink>
      {user?.client_type !== "agent" && (
          <RequestModal />
        )}

    </>
  );
}

export default SidebarLinks;
