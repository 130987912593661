import React, { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "contexts/UserContext"; // Импорт UserContext

// Создаем DataContext
export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [orders, setOrders] = useState([]); // Состояние для всех заказов
  const [isLoading, setIsLoading] = useState(true); // Состояние загрузки
  const { user, isLoading: isUserLoading } = useContext(UserContext); // Дожидаемся загрузки пользователя

  // Функция для загрузки всех заказов пользователя
  const fetchOrders = async (userId, userRole) => {

    try {
      const response = await fetch(
        `https://api.nppu.ru/api/orders/user-orders?user_id=${userId}&user_role=${userRole}`, // URL гейтвея
        {
          method: "GET",
          credentials: "include", // Для передачи куки
        }
      );
  
      if (!response.ok) {
        console.error("Ошибка загрузки заказов:", response.statusText);
        return [];
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка загрузки заказов:", error);
      return [];
    }
  };

  // Загружаем все заказы при изменении пользователя
  useEffect(() => {
    const loadOrders = async () => {
      if (user) {
        setIsLoading(true); // Устанавливаем состояние загрузки
        const loadedOrders = await fetchOrders(user.client_id, user.client_type);
        setOrders(loadedOrders);
        setIsLoading(false); // Завершаем загрузку
      }
    };

    if (window.location.pathname.includes("admin")) {
      loadOrders();
    }
  }, [user, isUserLoading]);

  // Функция для загрузки заявки по ID
  const fetchOrderById = async (orderId) => {
    setIsLoading(true); // Устанавливаем состояние загрузки
    try {
      const response = await fetch(`https://api.nppu.ru/api/orders/user-orders/${orderId}`, {
        method: "GET",
        credentials: "include", // Передаем куки
      });
  
      if (!response.ok) {
        throw new Error("Ошибка загрузки заявки");
      }
  
      const order = await response.json();
      return order;
    } catch (error) {
      console.error("Ошибка загрузки заявки:", error);
      return null;
    } finally {
      setIsLoading(false); // Завершаем загрузку
    }
  };
  

  const cleanFormData = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        acc[key] = value; // Добавляем только непустые значения
      }
      return acc;
    }, {});
  };

  const createOrder = async (status, client_id, agent_id, formData) => {
    console.log(status)
    try {
      const orderData = cleanFormData({
        ...formData,
        status,
        client_id,
        agent_id,
      });
  
      console.log("Отправка заявки на сервер:", orderData);
  
      const response = await fetch("https://api.nppu.ru/api/orders/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Передаем куки с запросом
        body: JSON.stringify(orderData),
      });
  
      if (!response.ok) {
        console.error("Ответ сервера:", await response.text());
        throw new Error("Ошибка при создании заявки");
      }
  
      const result = await response.json();
      console.log("Заявка успешно создана:", result);
      setOrders((prevOrders) => [...prevOrders, result]);
      return result; // Возвращаем результат, если нужно использовать его в вызывающем коде
    } catch (error) {
      console.error("Ошибка при создании заявки:", error.message);
      throw error; // Пробрасываем ошибку для обработки в вызывающем коде
    }
  };
  

  const updateOrder = async (id, formData) => {
    try {
      const orderData = cleanFormData(formData);
  
      console.log("Обновление заявки на сервер:", orderData);
  
      const response = await fetch(`https://api.nppu.ru/api/orders/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Передаем куки с запросом
        body: JSON.stringify(orderData),
      });
  
      if (!response.ok) {
        console.error("Ответ сервера:", await response.text());
        throw new Error("Ошибка при обновлении заявки");
      }
  
      const result = await response.json();
      console.log("Заявка успешно обновлена:", result);
      return result;
    } catch (error) {
      console.error("Ошибка при обновлении заявки:", error.message);
      throw error;
    }
  };
  
  

  return (
    <DataContext.Provider value={{ orders, setOrders, isLoading, fetchOrderById, createOrder, updateOrder }}>
      {children}
    </DataContext.Provider>
  );
};
