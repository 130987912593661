import React, { useState } from "react";
import { Box, Input, IconButton, Button, HStack } from "@chakra-ui/react";
import { FiPaperclip, FiSend } from "react-icons/fi";

const MessageInput = ({ userId, onSend }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState(null);

  const handleSend = () => {
    if (!message) return; // Запрет на отправку пустого сообщения
  
    const data = {
      content: message,
      userId: userId, // Добавляем идентификатор пользователя
    };
  
    onSend(data); // Передаем объект вместо FormData
    setMessage("");
    setFiles(null);
  };

  return (
    <Box bg="white" p={3} borderTop="1px solid gray">
      <HStack spacing={3}>
        {/* Иконка для загрузки файла */}
        <IconButton
          icon={<FiPaperclip />}
          variant="ghost"
          aria-label="Attach file"
          onClick={() => document.getElementById("fileInput").click()}
        />

        {/* Скрытое поле загрузки файла */}
        <Input
          id="fileInput"
          type="file"
          multiple
          onChange={(e) => setFiles(e.target.files)}
          display="none"
        />

        {/* Поле ввода текста */}
        <Input
          placeholder="Введите сообщение"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          flex="1"
        />

        {/* Кнопка отправки */}
        <IconButton
          icon={<FiSend />}
          colorScheme="blue"
          aria-label="Send message"
          onClick={handleSend}
        />
      </HStack>
    </Box>
  );
};

export default MessageInput;
