import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  Text,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useContext } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";

import { DataContext } from "contexts/DataContext";
import { UserContext } from "contexts/UserContext";
import SimpleTable from "views/admin/orders/components/SimpleTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import CurrencyRates from "views/admin/default/components/CurrencyRates";
import { MdDrafts, MdPostAdd, MdWorkOutline, MdDone, MdOutgoingMail, MdIncomingMail, MdCancel } from "react-icons/md";


export default function UserReports() {
  const { orders } = useContext(DataContext);
  const { user, isLoading } = useContext(UserContext);
    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Text>Загрузка...</Text>
      </Flex>
    );
  }

  const statuses_c = ["требуется доработка", "требуется доработка (валютный контроль)", "требуется доработка (служба безопасности)", "требуется доработка (служба финансового мониторинга)", "согласование договора", "требуется доработка договора", "согласование стоимости перевода", "согласование стоимости перевода клиентом", "согласование поручения на перевод", "согласование стоимости перевода клиентом", "подтвердите платеж", "требуется доработка (платеж)", "ожидание оплаты клиентом", "согласование акта", "требуется доработка акта"];
  const clientOrders = orders.filter((order) => statuses_c.includes(order.status));
  const statuses_a = ["в обработке", "верификация заявки", "валютный контроль", "проверка службой безопасности", "проверка службы финансового мониторинга", "договор на согласовании у агента", "согласование стоимости перевода агентом", "на согласовании у агента", "оплата клиентом подтверждена. ожидайте оплаты агентом", "акт на согласовании агента"];
  const agentOrders = orders.filter((order) => statuses_a.includes(order.status));
  
  const exchangeRates = {
    "USD-RUB": 76.23,
    "RUB-USD": 0.013,
    "EUR-RUB": 85.67,
    "RUB-EUR": 0.012,
    "CNY-RUB": 10.74,
    "RUB-CNY": 0.093,
    "AED-RUB": 20.75,
    "RUB-AED": 0.048,
    "CNH-RUB": 10.64,
    "RUB-CNH": 0.094,
    // Дополнительно
    "EUR-USD": 1.1, // Пример курса для пары EUR-USD
    "USD-EUR": 0.91,
    "CNY-USD": 0.14, // Прямой курс для юаней
    "USD-CNY": 7.1,
    "AED-USD": 0.27,
    "USD-AED": 3.67,
    "CNH-USD": 0.14,
    "USD-CNH": 7.2,
  };

  function calculateFilteredTotalInUSD(orders, rates, filterKey, filterValue) {
    if (!orders || orders.length === 0) return { sum: 0, quantity: 0 };
  
    let totalUSD = 0;
    let quantity = 0;
  
    orders.forEach((order) => {
      if (filterKey && filterValue && order[filterKey] !== filterValue) {
        return; // Пропускаем, если заявка не соответствует фильтру
      }
  
      const { payment_amount, currency } = order;
  
      if (currency === "USD") {
        // Если валюта уже USD, добавляем напрямую
        totalUSD += payment_amount;
      } else {
        // Попробуем найти прямую пару
        const directRate = rates[`${currency}-USD`];
        if (directRate) {
          totalUSD += payment_amount * directRate;
        } else {
          // Если прямой пары нет, ищем через RUB
          const toRubRate = rates[`${currency}-RUB`];
          const fromRubRate = rates[`RUB-USD`];
  
          if (toRubRate && fromRubRate) {
            // Конвертируем сначала в RUB, затем в USD
            totalUSD += payment_amount * toRubRate * fromRubRate;
          } else {
            console.warn(`Курс конверсии для ${currency}-USD не найден, и нет пути через RUB.`);
          }
        }
      }
  
      quantity += 1; // Увеличиваем счётчик заявок
    });
  
    return { sum: Math.round(totalUSD), quantity };
  }
  

  const calculations = {
    draft: calculateFilteredTotalInUSD(orders, exchangeRates, "status", "черновик"),
    inProcessing: calculateFilteredTotalInUSD(orders, exchangeRates, "status", "в обработке"),
    active: calculateFilteredTotalInUSD(
      orders.filter(
        (order) => !["черновик", "исполнена", "отклонена"].includes(order.status)
      ),
      exchangeRates
    ),
    agent: calculateFilteredTotalInUSD(agentOrders, exchangeRates),
    client: calculateFilteredTotalInUSD(clientOrders, exchangeRates),
    completed: calculateFilteredTotalInUSD(orders, exchangeRates, "status", "исполнена"),
    declined: calculateFilteredTotalInUSD(orders, exchangeRates, "status", "отклонена"),
  };
  console.log(calculations)

  
  return (
  <>
    <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px" >
    <IconBox
          w='56px'
          h='56px'
          bg="white"
          float="left"
          icon={
            <Icon w='32px' h='32px' as={MdBarChart} color="navy.500" />
          }
        />
<Text pl="20px" fontSize="30px" fontWeight="bold" lineHeight="140%">Аналитика</Text>
    </Flex>

    <Box>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
      {user.client_type === "client" ? (
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdDrafts} 
                  color='white'
                />
              }
            />
          }
          name="Мои черновики"
          sum={calculations.draft.sum}
          quantity={calculations.draft.quantity}
        />
      ) : (
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdPostAdd} // Иконка для остальных
                  color='white'
                />
              }
            />
          }
          name="Новые заявки"
          sum={calculations.inProcessing.sum}
          quantity={calculations.inProcessing.quantity}
        />
      )}
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={
                <Icon w='32px' h='32px' as={MdWorkOutline} color='white' />
              }
            />
          }
          name='Заявки в работе'
          sum={calculations.active.sum}
          quantity={calculations.active.quantity}
        />
        <MiniStatistics
                    startContent={
                      <IconBox
                        w='56px'
                        h='56px'
                        bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                        icon={
                          <Icon w='32px' h='32px' as={MdDone} color='white' />
                        }
                      />
                    }
          name='Заявки исполнены'
          sum={calculations.completed.sum}
          quantity={calculations.completed.quantity}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAttachMoney} color='white' />}
            />
          }
          name='Запросы от агента'
          sum={calculations.agent.sum}
          quantity={calculations.agent.quantity}
        />
                <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='Запросы на агента'
          sum={calculations.client.sum}
          quantity={calculations.client.quantity}
        />
                <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdCancel} color='white' />}
            />
          }
          name='Заявки отклоненные'
          sum={calculations.declined.sum}
          quantity={calculations.declined.quantity}
        />

      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
      <CurrencyRates />
      {user.client_type === "agent" && (
      <SimpleTable
            tableData={orders}
            title="Последние заявки"
            link="/admin/orders"
            limit="5" />
          )}
            {user.client_type === "client" && (
        <SimpleTable
            tableData={orders}
            title="Мои черновики"
            status="черновик"
            link="/admin/orders/drafts"
            limit="5" />
          )}
            <SimpleTable
            tableData={clientOrders}
            title="Ожидание действий клиента"
            link="/admin/orders/client" />
                        <SimpleTable
            tableData={agentOrders}
            title="Ожидание действий агента"
            link="/admin/orders/agent" />
    
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap='20px' mb='20px'>
      <DailyTraffic />
          <PieCard />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
      </SimpleGrid>
    </Box>
    </>
  );
}
