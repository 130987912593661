import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState, useContext } from 'react';
import { UserProvider, UserContext } from "contexts/UserContext";
import { DataProvider } from "contexts/DataContext";
// Chakra imports

export default function Main() {

  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  const ProtectedRoute = ({ children }) => {
    const { user } = useContext(UserContext); // Получаем текущего пользователя из контекста

    if (!user || user.client_id !== 1) {
      // Если пользователь не авторизован или client_id не равен 1, перенаправляем на страницу входа
      return <Navigate to="/auth/sign-in" />;
    }

    // Если все проверки пройдены, рендерим переданный компонент
    return children;
  };

  return (
    <UserProvider> 
     <DataProvider>
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />

        <Route path="/admin/*" element={
    localStorage.getItem("user") !== null ? (
      <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
    ) : (
      <Navigate to="/auth/sign-in" />
    )
  }
/>
<Route
            path="/protected/*"
            element={
              <ProtectedRoute>
                <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
              </ProtectedRoute>
            }
          />

        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      </Routes>
    </ChakraProvider>
    </DataProvider> 
    </UserProvider>
  );
}
