import React, { useContext } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import ChatWindow from "views/admin/profile/components/ChatWindow";
import { UserContext } from "contexts/UserContext";

const ChatPage = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }


  const userId = user.client_id;

  return <ChatWindow userId={userId} userName={user.name} />;
};

export default ChatPage;
