import React, { useContext } from 'react';
import {
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdCallMade, MdCallReceived } from 'react-icons/md';
import Card from 'components/card/Card';
import { UserContext } from "contexts/UserContext";

export default function ComplexTable({ tableData, title, status, link, limit = 4 }) {
  const { isLoading } = useContext(UserContext); // Добавлено isLoading из UserContext
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/admin/orders/${id}`);
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Text>Загрузка...</Text>
      </Flex>
    );
  }

  // Фильтруем и ограничиваем количество элементов
  const filteredData = (status ? tableData.filter((row) => row.status === status) : tableData)
    .slice(-limit)
    .sort((a, b) => new Date(b.datum) - new Date(a.datum)); // Сортировка по дате, от новой к старой

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Возвращаем пустую строку, если дата отсутствует
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <Card flexDirection="column" w="100%" p="20px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex justify="space-between" align="center" mb="5px">
        <Text
          color={textColor}
          mt="10px"
          mb="25px"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {title}
        </Text>
        {link && (
          <Text
            as="a"
            href={link}
            color="blue.500"
            fontSize="sm"
            fontWeight="500"
            _hover={{ textDecoration: "underline" }}
          >
            Смотреть все
          </Text>
        )}
      </Flex>

      <Table variant="striped" color="gray.500" mb="24px" mt="12px">
        <Thead>
          <Tr>
            <Th fontSize="10px" textAlign="center">Тип</Th>
            <Th fontSize="10px" textAlign="center">Дата</Th>
            <Th fontSize="10px" textAlign="center">Получатель</Th>
            <Th fontSize="10px" textAlign="center">Сумма</Th>
            <Th fontSize="10px" textAlign="center">Отправитель</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredData.map((row) => (
            <Tr
              key={row.id}
              cursor="pointer"
              _hover={{ backgroundColor: 'gray.200' }}
              onClick={() => handleRowClick(row.id)}
            >
              <Td>
                <Flex align="center">
                  {row.type === 'export' ? (
                    <Tooltip label="Получаю деньги (Экспортер)" fontSize="xs" placement="top" hasArrow>
                      <span>
                        <Icon as={MdCallReceived} w={5} h={5} color="green.500" />
                      </span>
                    </Tooltip>
                  ) : row.type === 'import' ? (
                    <Tooltip label="Отправляю деньги (Импортер)" fontSize="xs" placement="top" hasArrow>
                      <span>
                        <Icon as={MdCallMade} w={5} h={5} color="blue.500" />
                      </span>
                    </Tooltip>
                  ) : null}
                </Flex>
              </Td>
              <Td>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                {formatDate(row.datum)}
                </Text>
              </Td>
              <Td>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.type === 'import' ? row.partner_name : row.short_company_name}
                </Text>
              </Td>
              <Td>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.payment_amount}&nbsp;{row.currency}
                </Text>
              </Td>
              <Td>
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {row.type === 'import' ? row.short_company_name : row.partner_name}
                </Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Card>
  );
}
