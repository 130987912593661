import { Avatar, Box, Flex, SimpleGrid, Text, useColorModeValue, IconButton, Input, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState, useContext } from "react";
import { EditIcon } from "@chakra-ui/icons";
import ModalChangePassword from "./ChangePass";
import banner from "assets/img/auth/banner.png";
import { UserContext } from "contexts/UserContext";

export default function Banner({ user, agent, formatDate }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const userStatus = user?.client_type === "client" ? "Клиент с " +  formatDate(user?.datum) : "Агент с " + formatDate(user?.datum);

  const { updateUserField } = useContext(UserContext); // Достаем метод из контекста
  const [editingField, setEditingField] = useState(null);
  const [editedValues, setEditedValues] = useState({
    name: user?.name || "",
    phone: user?.phone || "",
    email: user?.email || "",
    login: user?.login || "",
  });

  // Обработчик клика для поля редактирования
  const handleEditClick = (field) => {
    setEditingField(field);
  };

  // Обработчик изменения значений
  const handleInputChange = (field, value) => {
    setEditedValues((prev) => ({ ...prev, [field]: value }));
  };

  // Сохранение изменений
  const handleSave = async () => {
    if (!editingField) {
      console.error("Поле для редактирования не выбрано.");
      return;
    }
  
    const newValue = editedValues[editingField];
  
    if (!newValue) {
      console.error(`Новое значение для поля ${editingField} отсутствует.`);
      return;
    }
  
    try {
      if (typeof updateUserField !== "function") {
        throw new Error("Метод updateUserField не доступен в контексте.");
      }
  
      await updateUserField(editingField, newValue);
      setEditingField(null); // Завершаем редактирование
    } catch (error) {
      console.error(`Ошибка при обновлении поля ${editingField}:`, error);
    }
  };
  
  // Открытие и закрытие модального окна для смены пароля
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Стиль для полей ввода
  const inputStyles = {
    height: "1.5rem",
    border: "none",
    padding: 0,
    borderBottom: "1px solid #E2E8F0",
    borderRadius: "0",
    _hover: {
      border: "none",
      borderBottom: "1px solid #A0AEC0", // Цвет при наведении
    },
    _focus: {
      border: "none",
      borderBottom: "1px solid #3965FF", // Цвет при фокусе
      boxShadow: "none",
    },
  };

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center">
      <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="85px"
        w="100%"
      />
      <Avatar
        mx="auto"
        h="87px"
        w="87px"
        mt="-43px"
        bg="#11047A"
        border="4px solid white"
        
        name={user.name} // Инициал отображается, если src не задан
      />

      {/* Editable Name */}
      <Flex width="fit-content" position="relative" margin="10px auto 0" pl="2rem" pr="2rem" _hover={{ ".edit-icon": { display: "flex" } }}>
        {editingField === "name" ? (
          <Input
            {...inputStyles}
            value={editedValues.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            onBlur={handleSave} // Сохраняем при потере фокуса
            autoFocus
          />
        ) : (
          <Text color={textColorPrimary} fontWeight="bold" fontSize="xl">
            {editedValues.name || "Введите ваше имя"}
          </Text>
        )}
        {editingField !== "name" && (
          <IconButton
            icon={<EditIcon />}
            size="xs"
            variant="ghost"
            position="absolute"
            right="0"
            className="edit-icon"
            display="none"
            onClick={() => handleEditClick("name")}
          />
        )}
      </Flex>

      <Flex width="fit-content" position="relative" margin="0 auto" pl="2rem" pr="2rem">
        <Text color={textColorSecondary} fontSize="sm">
          {user?.short_company_name || ""}
        </Text>
      </Flex>

      <SimpleGrid columns="2" gap="10px" mt="26px">
        {/* Editable Login */}
        <Flex alignItems="flex-start" position="relative" _hover={{ ".edit-icon": { display: "flex" } }}>
          <Flex alignItems="flex-start" justifyContent="flex-start" direction="column">
            <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
              Логин
            </Text>
            {editingField === "login" ? (
              <Input
                {...inputStyles}
                value={editedValues.login}
                onChange={(e) => handleInputChange("login", e.target.value)}
                onBlur={handleSave}
                autoFocus
              />
            ) : (
              <Text color={textColorPrimary} fontWeight="500" fontSize="md">
                {editedValues.login || "Логин не указан"}
              </Text>
            )}
          </Flex>
          {editingField !== "login" && (
            <IconButton
              icon={<EditIcon />}
              size="xs"
              variant="ghost"
              position="absolute"
              right="10px"
              className="edit-icon"
              display="none"
              onClick={() => handleEditClick("login")}
            />
          )}
        </Flex>

        {/* Change Password Link */}
        <Flex alignItems="flex-start" justifyContent="flex-start" direction="column">
          <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
            Пароль
          </Text>
          <Flex alignItems="center" justifyContent="flex-start">
          <Text color={textColorPrimary} fontWeight="500" mt="6px" mb="-6px" fontSize="md">
            ***** </Text>
            <Text
              as="span"
              color="blue.500"
              fontWeight="normal"
              fontSize="sm"
              mb="-6px"
              cursor="pointer"
              onClick={openModal}
              ml={2}
              _hover={{ textDecoration: "underline" }}
            >
              Изменить пароль
            </Text>
            </Flex>
        </Flex>

        {/* Editable Phone */}
        <Flex align="flex-start" position="relative" _hover={{ ".edit-icon": { display: "flex" } }}>
          <Flex alignItems="flex-start" justifyContent="flex-start" direction="column">
            <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
              Телефон
            </Text>
            {editingField === "phone" ? (
              <Input
                {...inputStyles}
                value={editedValues.phone}
                onChange={(e) => handleInputChange("phone", e.target.value)}
                onBlur={handleSave}
                autoFocus
              />
            ) : (
              <Text color={textColorPrimary} fontWeight="500" fontSize="md">
                {editedValues.phone || "+7 000 000 0000"}
              </Text>
            )}
          </Flex>
          {editingField !== "phone" && (
            <IconButton
              icon={<EditIcon />}
              size="xs"
              variant="ghost"
              position="absolute"
              right="10px"
              className="edit-icon"
              display="none"
              onClick={() => handleEditClick("phone")}
            />
          )}
        </Flex>

        {/* Editable Email */}
        <Flex align="flex-start" position="relative" _hover={{ ".edit-icon": { display: "flex" } }}>
          <Flex alignItems="flex-start" justifyContent="flex-start" direction="column">
            <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
              Email
            </Text>
            {editingField === "email" ? (
              <Input
                {...inputStyles}
                value={editedValues.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                onBlur={handleSave}
                autoFocus
              />
            ) : (
              <Text color={textColorPrimary} fontWeight="500" fontSize="md">
                {editedValues.email || "email@example.com"}
              </Text>
            )}
          </Flex>
          {editingField !== "email" && (
            <IconButton
              icon={<EditIcon />}
              size="xs"
              variant="ghost"
              position="absolute"
              right="10px"
              className="edit-icon"
              display="none"
              onClick={() => handleEditClick("email")}
            />
          )}
        </Flex>

        {/* User Status */}
        <Flex alignItems="flex-start" justifyContent="flex-start" direction="column">
          <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
            Ваш статус
          </Text>
          <Text color={textColorPrimary} fontWeight="500" fontSize="md">
            {userStatus || "Клиент"}
          </Text>
        </Flex>

        {/* Agent Name */}
        <Flex alignItems="flex-start" justifyContent="flex-start" direction="column">
          <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
            Ваш агент
          </Text>
          <Text color={textColorPrimary} fontWeight="500" fontSize="md">
            {agent?.name || "Имя агента"}
          </Text>
        </Flex>
      </SimpleGrid>

      {/* Save Button */}
      {editingField && (
        <Button
          onClick={handleSave}
          fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          position="relative"
          width="fit-content"
          colorScheme="blue"
          margin="15px auto 0"
        >
          Сохранить изменения
        </Button>
      )}

      <ModalChangePassword isOpen={isModalOpen} onClose={closeModal} />
    </Card>
  );
}
