import React, { useState, useContext } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { UserContext } from "contexts/UserContext";
import { DataContext } from "contexts/DataContext";
import { MdCreate } from "react-icons/md";
import RequestFormSteps from './RequestFormSteps'; // Подключаем компонент шагов формы

const RequestModal = () => {
  const { user } = useContext(UserContext);
  const { createOrder } = useContext(DataContext); 
  const { isOpen, onOpen: openModal, onClose } = useDisclosure();
  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure(); // Модальное окно подтверждения
  const { isOpen: isDraftOpen, onOpen: onDraftOpen, onClose: onDraftClose } = useDisclosure(); // Модальное окно для черновиков
  const [isFormModified, setIsFormModified] = useState(false); // Состояние для отслеживания изменений в форме




  const [formData, setFormData] = useState({
    type: '',
    partner_bank_name: '',
    partner_bank_country: '',
    partner_bank_address: '',
    partner_bank_swift: '',
    partner_name: '',
    partner_address: '',
    partner_website: '',
    product_description: '',
    invoice_number: '',
    invoice_date: '',
    contract_number: '',
    contract_date: '',
    tnvd_code: '',
    payment_purpose: '',
    payment_amount: '',
    currency: '',
    contract_name: '',
    invoice_name: '',
    card_name: '',
    specialization_name: '',
    rucard_name: '',
  });

 

  // Обработка закрытия основного модального окна
  const handleClose = () => {
    if (isFormModified) {
      onDraftOpen(); // Открываем модальное окно с вопросом о сохранении черновика
    } else {
      onClose(); // Если изменений нет, просто закрываем
    }
  };



  const handleSaveDraft = async () => {
    await createOrder("черновик", user.client_id, user.agent_id, formData );
    onDraftClose();
    onClose(); // Закрываем оба модальных окна
    resetForm(); // Сбрасываем форму
  };

  const handleSubmit = async () => {
    await createOrder("в обработке", user.client_id, user.agent_id, formData);
    onClose(); // Закрыть основное модальное окно с формой
    onSuccessOpen(); // Открыть модальное окно подтверждения
    resetForm(); // Сбросить форму
  };

  const handleFormModified = () => {
    setIsFormModified(true);
  };

  const handleFormUpdate = (updatedData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...updatedData, // Обновляем только изменённые поля
    }));
    setIsFormModified(true); // Фиксируем, что форма была изменена
  };

  const handleFileUpdate = (fieldName, fileName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: fileName, // Обновляем соответствующее поле
    }));
  };


  // Функция для сброса данных формы
  const resetForm = () => {
    setFormData({
      type: '',
      partner_bank_name: '',
      partner_bank_country: '',
      partner_bank_address: '',
      partner_bank_swift: '',
      partner_name: '',
      partner_address: '',
      partner_website: '',
      product_description: '',
      invoice_number: '',
      invoice_date: '',
      contract_number: '',
      contract_date: '',
      tnvd_code: '',
      payment_purpose: '',
      payment_amount: '',
      currency: '',
      contract_name: '',
      invoice_name: '',
      card_name: '',
      specialization_name: '',
      rucard_name: ''
    });
    setIsFormModified(false); // Сбрасываем состояние изменений
  };

  // Функция открытия модального окна
  const handleOpen = () => {
    resetForm(); // Сброс формы перед открытием
    openModal(); // Открытие модального окна
  };

  return (
    <>
      <Button mt={25} onClick={handleOpen} rightIcon={<MdCreate />} colorScheme='blue' variant='outline'>
        Создать заявку
      </Button>

      {/* Модальное окно с формой */}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW="80%" maxH="fit-content" background='secondaryGray.300' p="20px">
          <ModalBody>
            <RequestFormSteps
              formData={formData}
              handleSubmit={handleSubmit}
              handleFormUpdate={handleFormUpdate}
              handleFileUpdate={handleFileUpdate}
              setFormModified={handleFormModified}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Модальное окно с подтверждением отправки */}
      <Modal isOpen={isSuccessOpen} onClose={onSuccessClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Заявка успешно сохранена!</ModalHeader>
          <ModalBody>
            <Text>Ваша заявка успешно сохранена и направлена в работу.</Text>
          </ModalBody>
          <ModalFooter>
            <Button color="green.500" onClick={onSuccessClose}>
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Модальное окно с вопросом о черновике */}
      <Modal isOpen={isDraftOpen} onClose={onDraftClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Сохранить заявку в черновиках?</ModalHeader>
          <ModalBody>
            <Text>Вы внесли изменения. Хотите сохранить заявку в черновиках перед закрытием?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveDraft}>
              Да
            </Button>
            <Button colorScheme="red" onClick={() => { onDraftClose(); onClose(); resetForm(); }}>
              Нет
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestModal;
