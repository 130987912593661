import React from 'react';

const TransferTemplate = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: 'auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    title: {
      textAlign: 'center',
      color: '#333',
    },
    paragraph: {
      margin: '10px 0',
      lineHeight: '1.6',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      margin: '20px 0',
    },
    th: {
      border: '1px solid #ddd',
      padding: '8px',
      textAlign: 'center',
      backgroundColor: '#f2f2f2',
    },
    td: {
      border: '1px solid #ddd',
      padding: '8px',
      textAlign: 'center',
    },
    signatureBox: {
      marginTop: '20px',
      textAlign: 'center',
      fontWeight: 'bold',
      padding: '10px',
      borderTop: '1px solid #ddd',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>ПОРУЧЕНИЕ № ___</h1>
      <h2 style={styles.title}>Принципала Агенту по оплате Денежных требований</h2>
      <p style={styles.paragraph}>«___» ________ 20__ г.</p>
      <p style={styles.paragraph}>
        В соответствии с пунктом 3.2 Агентского договора № ___ от «___» ___________ 20___ г. (далее – Договор)
        Принципал дает поручение Агенту на оплату следующих Денежных требований по Контрактам:
      </p>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>№</th>
            <th style={styles.th}>Наименование Поставщика</th>
            <th style={styles.th}>Дата и Номер Контракта</th>
            <th style={styles.th}>Документ, подтверждающий Денежное требование</th>
            <th style={styles.th}>Сумма Платежа</th>
            <th style={styles.th}>Валюта Платежа</th>
            <th style={styles.th}>Комиссия за Платеж (без НДС)</th>
            <th style={styles.th}>Реквизиты Поставщика</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styles.td}>___</td>
            <td style={styles.td}>___</td>
            <td style={styles.td}>___</td>
            <td style={styles.td}>___</td>
            <td style={styles.td}>___</td>
            <td style={styles.td}>___</td>
            <td style={styles.td}>___</td>
            <td style={styles.td}>___</td>
          </tr>
        </tbody>
      </table>

      <p style={styles.paragraph}>Итого Возмещение за платеж составляет ___________ руб., __ коп.</p>
      <p style={styles.paragraph}>Комиссия за платеж составляет __________ руб., __ коп.</p>

      <h3>Принципал:</h3>
      <p style={styles.paragraph}>От имени Принципала:</p>
      <p style={styles.paragraph}>Генеральный директор ____________ /</p>
      <div style={styles.signatureBox}>Отметка об исполнении</div>
    </div>
  );
};

export default TransferTemplate;
