import React, { useEffect, useRef } from "react";
import { Box, Text, VStack, Divider, Fade } from "@chakra-ui/react";

const MessageBox = ({ userId, messages }) => {
  const messageEndRef = useRef(null);

  // Автоматическая прокрутка вниз при добавлении сообщений
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const renderMessages = () => {
    let lastDate = null;


    return messages.map((msg, index) => {
      const normalizedClientId = Number(msg.client_id)
      const currentDate = formatDate(msg.created_at);
      const showDateDivider = currentDate !== lastDate;
      lastDate = currentDate;

      return (
        <Fade key={msg.id} in>
          {/* Разделитель для даты */}
          {showDateDivider && (
            <Box my={3}>
              <Divider />
              <Text textAlign="center" fontSize="xs" color="gray.500" mt={2}>
                {currentDate}
              </Text>
            </Box>
          )}
          {/* Сообщение */}
          <Box
            bg={normalizedClientId === 1 ? "#ecffe4" : (normalizedClientId === userId ? "#bee3f8" : "#ffeccc")}
            p={4}
            borderRadius="lg"
            boxShadow="sm"
            float={normalizedClientId === userId ? "right" : "left"}
            width="50%"
            maxWidth="300px"
            position="relative"
            padding={2}
            mb={6} // Увеличенные отступы между сообщениями
            _after={
              normalizedClientId === 1
    ? {
      content: '""',
      position: "absolute",
      bottom: "-15px",
      left: "20px",
      width: "0",
      height: "0",
      borderStyle: "solid",
      borderWidth: "15px 20px 0 0",
      borderColor: "#ecffe4 transparent transparent transparent",
    }
    : normalizedClientId === userId
    ? {
      content: '""',
      position: "absolute",
      bottom: "-15px",
      right: "20px",
      width: "0",
      height: "0",
      borderStyle: "solid",
      borderWidth: "15px 0 0 20px",
      borderColor: "#bee3f8 transparent transparent transparent", // Цвет для normalizedClientId === userId
    }
  : {
      content: '""',
      position: "absolute",
      bottom: "-15px",
      left: "20px",
      width: "0",
      height: "0",
      borderStyle: "solid",
      borderWidth: "15px 20px 0 0",
      borderColor: "#ffeccc transparent transparent transparent", // Цвет для остальных случаев
    }
            }
          >
   
            <Text fontSize="sm" color="black" mb="1px">
              {msg.content}
            </Text>
            <Text fontSize="10px" color="black" textAlign="right">
            {msg.user_name} | {formatDateTime(msg.created_at)}
            </Text>
          </Box>
        </Fade>
      );
    });
  };

  return (
    <Box
      bg="white"
      p={4}
      flex="1"
      overflowY="auto"
      borderRadius="lg"
      maxHeight="600px" // Ограничение по высоте
    >
      <VStack align="stretch" spacing={6}>
        {renderMessages()}
        <div ref={messageEndRef} /> {/* Прокрутка к последнему сообщению */}
      </VStack>
    </Box>
  );
};

export default MessageBox;
