import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Text,
  FormControl,
  FormLabel,
  useColorModeValue,
  SimpleGrid,
  Select,
  Icon,
  useToast
} from '@chakra-ui/react';
import { BsBank, BsCashCoin } from "react-icons/bs";
import { TbUserDollar } from "react-icons/tb"
import FileUpload from "views/admin/profile/components/Upload";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { MdDescription, MdSearch, MdArrowDownward, MdCheckCircle } from "react-icons/md";

import IconBox from "components/icons/IconBox";

import { DataContext } from "contexts/DataContext";

const OrderParams = ({ order, user, updateStatus }) => {
  const { updateOrder } = useContext(DataContext); 
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [contractFileName, setContractFileName] = useState(null);
  const [isContractUploaded, setIsContractUploaded] = useState(false);
  const [invoiceFileName, setInvoiceFileName] = useState(null);
  const [isInvoiceUploaded, setIsInvoiceUploaded] = useState(false);
  const [companyCardFileName, setCompanyCardFileName] = useState("");
  const [isCompanyCardUploaded, setIsCompanyCardUploaded] = useState(false);
  const [specializationFileName, setSpecializationFileName] = useState("");
  const [isSpecializationUploaded, setIsSpecializationUploaded] = useState(false);
  const [russiaCompanyCardFileName, setRussiaCompanyCardFileName] = useState("");
  const [isRussiaCompanyCardUploaded, setIsRussiaCompanyCardUploaded] = useState(false);
  const [formData, setFormData] = useState(order); // Состояние для полей заявки
  const [isChanged, setIsChanged] = useState(false); // Состояние для отслеживания изменений
  const [isLoading, setIsLoading] = useState(false);

  const inputBgColor = useColorModeValue('white', 'gray.800'); // Цвет фона инпутов
  const [isEditing, setIsEditing] = useState(false);


  const handleFileUpload = (name, value) => {
    setIsChanged(true);
    handleFormUpdate({ [name]: value }); // Обновляем конкретное поле
  };

  const handleFormUpdate = (updatedData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...updatedData, // Обновляем только изменённые поля
    }));
  };

    // Функция обработки загрузки файлов для контракта
    const handleContractUploadComplete = (fieldName, fileName) => {
      setContractFileName(fileName); // Устанавливаем имя файла
      handleFileUpload(fieldName, fileName);
      setIsContractUploaded(true); // Обновляем состояние загрузки
    };


    const handleInvoiceUploadComplete = (fieldName, fileName) => {
      setInvoiceFileName(fileName); // Устанавливаем название загруженного файла
      handleFileUpload(fieldName, fileName);
      setIsInvoiceUploaded(true); // Обновляем состояние, что файл загружен
    };

    const handleCompanyCardUploadComplete = (fieldName, fileName) => {
      setCompanyCardFileName(fileName); // Устанавливаем название загруженного файла
      handleFileUpload(fieldName, fileName);
      setIsCompanyCardUploaded(true); // Обновляем состояние, что файл загружен
    };
    
    const handleSpecializationUploadComplete = (fieldName, fileName) => {
      setSpecializationFileName(fileName); // Устанавливаем название загруженного файла
      handleFileUpload(fieldName, fileName);
      setIsSpecializationUploaded(true); // Обновляем состояние, что файл загружен
    };

    const handleRussiaCompanyCardUploadComplete = (fieldName, fileName) => {
      setRussiaCompanyCardFileName(fileName); // Устанавливаем название загруженного файла
      handleFileUpload(fieldName, fileName);
      setIsRussiaCompanyCardUploaded(true); // Обновляем состояние, что файл загружен
    };

  
  // Обработчик для кнопки "Изменить"
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const isFormEditable =
  user.client_type === 'agent' || (user.client_type === 'client' && order.status === 'черновик');

  const toast = useToast(); // Хук для работы с тостами

  const handleSave = async () => {
    if (!order || !order.id) {
      console.error('ID заказа отсутствует');
      return;
    }

    setIsLoading(true); // Устанавливаем состояние загрузки
    try {
      await updateOrder(order.id, formData); // Вызываем функцию обновления
      setIsChanged(false); // Сбрасываем флаг изменений

      // Показываем тост об успешном сохранении
      toast({
        title: 'Успех!',
        description: 'Ваши изменения успешно сохранены.',
        status: 'success',
        duration: 5000, // Длительность показа (в миллисекундах)
        isClosable: true, // Добавляет кнопку для закрытия
        position: 'bottom', // Позиция тостера
      });
    } catch (error) {
      console.error('Ошибка сохранения изменений:', error);

      // Показываем тост об ошибке
      toast({
        title: 'Ошибка!',
        description: 'Не удалось сохранить изменения. Попробуйте снова.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setIsLoading(false); // Сбрасываем состояние загрузки
    }
  };


  const operationType =  formData.type

  // Обработка изменений полей
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setIsChanged(true); // Отслеживаем изменения
  };

  const renderCheckmark = (index) => {
    return formData.check_marks[index] === 1 ? (
      <Icon as={MdCheckCircle} color="green" boxSize={5} ml={2} />
    ) : null;
  };

  const contractFileUrl = `https://api.nppu.ru/uploads/others/` + formData.contract_name;
  const invoiceFileUrl = `https://api.nppu.ru/uploads/others/` + formData.invoice_name;
  const cardFileUrl = `https://api.nppu.ru/uploads/others/` + formData.card_name;
  const specializationFileUrl = `https://api.nppu.ru/uploads/others/` + formData.specialization_name;
  const rucardFileUrl = `https://api.nppu.ru/uploads/others/` + formData.rucard_name;

  return (
    <Box>
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
        {/* Блок: Банк Получателя или Отправителя */}
        <Box p={4} bg="gray.100" borderRadius="md">
          <Flex alignItems="center" mb={4} >
    <IconBox
          w='30px'
          h='30px'
          bg="white"
          float="left"
          mr="10px"
          icon={
            <Icon w='22px' h='22px' as={BsBank} color="navy.400" />
          }
        />
<Text fontSize="md" fontWeight="bold">
{operationType === 'import' ? 'Банк Получателя / Beneficiary Bank' : 'Банк Отправителя / Sender Bank'}
</Text>
    </Flex>
          <Box>
            <FormControl>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
  <Box>
    <Text fontSize="sm">
      {operationType === 'import'
        ? 'Наименование Банка Получателя'
        : 'Наименование Банка Отправителя'}
    </Text>
  </Box>
  <Box>
    <Box as="span" fontSize="xs" color="gray.500">
      {operationType === 'import'
        ? 'Beneficiary Bank Name'
        : 'Sender Bank Name'}
    </Box>
  </Box>
</Flex>

{renderCheckmark(0)}
</Flex>
</FormLabel>
              <Input
                name="partner_bank_name"
                value={formData.partner_bank_name || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Страна Банка Получателя' : 'Страна Банка Отправителя'}
 </Text>
<Box as="span" fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Country' : 'Sender Bank Country'}
</Box>
</Flex>
{renderCheckmark(1)}
</Flex>
</FormLabel>
              <Input
                name="partner_bank_country"
                value={formData.partner_bank_country || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Адрес Банка Получателя' : 'Адрес Банка Отправителя'}
 </Text>
<Box as="span" fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Address' : 'Sender Bank Address'}
</Box>
</Flex>
{renderCheckmark(2)}
</Flex>
</FormLabel>
              <Input
                name="partner_bank_address"
                value={formData.partner_bank_address || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Код Банка Получателя (SWIFT)' : 'Код Банка Отправителя (SWIFT)'}
 </Text>
<Box as="span" fontSize="xs" color="gray.500">
  SWIFT ID# of Foreign Bank
</Box>
</Flex>
{renderCheckmark(3)}
</Flex>
</FormLabel>
              <Input
                name="partner_bank_swift"
                value={formData.partner_bank_swift || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>


            
          </Box>
        </Box>

        {/* Блок: Получатель */}
        <Box p={4} bg="gray.100" borderRadius="md">
        <Flex alignItems="center" mb={4} >
    <IconBox
          w='30px'
          h='30px'
          bg="white"
          float="left"
          mr="10px"
          icon={
            <Icon w='22px' h='22px' as={TbUserDollar} color="navy.400" />
          }
        />
<Text fontSize="md" fontWeight="bold">
{operationType === 'import' ? 'Получатель / Beneficiary' : 'Отправитель / Sender'}
</Text>
    </Flex>

          <Box>
            <FormControl>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Наименование Получателя' : 'Наименование Отправителя'}
 </Text>
<Box as="span" fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Name on Account' : 'Sender Name on Account'}
</Box>
</Flex>
{renderCheckmark(4)}
</Flex>
</FormLabel>
              <Input
                name="partner_name"
                value={formData.partner_name || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Адрес Получателя' : 'Адрес Отправителя'}
 </Text>
<Box as="span" fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Address' : 'Sender Address'}
</Box>
</Flex>
{renderCheckmark(5)}
</Flex>
</FormLabel>
              <Input
                name="partner_address"
                value={formData.partner_address || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Счет Получателя' : 'Счет Отправителя'}
 </Text>
<Box as="span" fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Account# or IBAN#' : 'Sender Bank Account# or IBAN#'}
</Box>
</Flex>
{renderCheckmark(6)}
</Flex>
</FormLabel>
              <Input
                name="partner_account"
                value={formData.partner_account || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>


            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Веб-сайт получателя' : 'Веб-сайт отправителя'}
 </Text>
<Box as="span" fontSize="xs" color="gray.500">
Website
</Box>
</Flex>
{renderCheckmark(7)}
</Flex>
</FormLabel>
              <Input
                name="ppartner_website"
                value={formData.partner_website || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

          </Box>
        </Box>

        {/* Блок: Контракт и счет */}
        <Box p={4} bg="gray.100" borderRadius="md">
        <Flex alignItems="center" mb={4} >
    <IconBox
          w='30px'
          h='30px'
          bg="white"
          float="left"
          mr="10px"
          icon={
            <Icon w='22px' h='22px' as={HiOutlineClipboardDocumentList} color="navy.400" />
          }
        />
<Text fontSize="md" fontWeight="bold">
Контракт и счёт / Contract and Invoice
</Text>
    </Flex>

          <Box>
            <FormControl>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Описание товара/услуги</Text>
<Box as="span" fontSize="xs" color="gray.500">Product/service description</Box>
</Flex>
{renderCheckmark(8)}
</Flex>
</FormLabel>
              <Input
                name="product_description"
                value={formData.product_description || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Номер инвойса</Text>
<Box as="span" fontSize="xs" color="gray.500">No of invoice</Box>
</Flex>
{renderCheckmark(9)}
</Flex>
</FormLabel>
              <Input
                name="invoice_number"
                value={formData.invoice_number || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Дата инвойса</Text>
<Box as="span" fontSize="xs" color="gray.500">Date of invoice</Box>
</Flex>
{renderCheckmark(10)}
</Flex>
</FormLabel>
              <Input
                name="invoice_date"
                type="date"
                value={formData.invoice_date || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">
{operationType === 'import' ? 'Номер контракта c поставщиком' : 'Номер контракта c покупателем'}
 </Text>
<Box as="span" fontSize="xs" color="gray.500">
{operationType === 'import' ? 'No of contract with supplier' : 'No of contract with buyer'}
</Box>
</Flex>
{renderCheckmark(11)}
</Flex>
</FormLabel>
              <Input
                name="contract_number"
                value={formData.contract_number || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">
            {operationType === 'import' ? 'Дата контракта c поставщиком' : 'Дата контракта c покупателем'}
 </Text>
<Box as="span" fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Date of contract with supplier' : 'Date of contract with buyer'}
</Box>
</Flex>
{renderCheckmark(12)}
</Flex>
</FormLabel>
              <Input
                name="contract_date"
                type="date"
                value={formData.contract_date || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Код ТНВД (необязательно)</Text>
<Box as="span" fontSize="xs" color="gray.500">TNVD Code</Box>
</Flex>
{renderCheckmark(13)}
</Flex>
</FormLabel>
              <Input
                name="tnvd_code"
                value={formData.tnvd_code || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>



          </Box>
        </Box>

        {/* Блок: Платеж */}
        <Box p={4} bg="gray.100" borderRadius="md">
        <Flex alignItems="center" mb={4} >
    <IconBox
          w='30px'
          h='30px'
          bg="white"
          float="left"
          mr="10px"
          icon={
            <Icon w='22px' h='22px' as={BsCashCoin} color="navy.400" />
          }
        />
<Text fontSize="md" fontWeight="bold">
Платеж / Payment
</Text>
    </Flex>
       
          <Box>
            <FormControl>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Назначение платежа</Text>
<Box as="span" fontSize="xs" color="gray.500">Details of payment</Box>
</Flex>
{renderCheckmark(14)}
</Flex>
</FormLabel>
              <Input
                name="payment_purpose"
                value={formData.payment_purpose || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Сумма платежа</Text>
<Box as="span" fontSize="xs" color="gray.500">Amount of payment</Box>
</Flex>
{renderCheckmark(15)}
</Flex>
</FormLabel>
              <Input
                name="payment_amount"
                type="number"
                value={formData.payment_amount || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Валюта</Text>
<Box as="span" fontSize="xs" color="gray.500">Currency</Box>
</Flex>
{renderCheckmark(14)}
</Flex>
</FormLabel>
              <Select
                name="currency"
                value={formData.currency || ''}
                onChange={handleChange}
                bg={inputBgColor}
                disabled={!isFormEditable}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="RUB">RUB</option>
                <option value="CNY">CNY</option>
                <option value="AED">AED</option>
                <option value="CNH">CNH</option>
              </Select>
            </FormControl>

   {/* Блок для подтверждения банковских реквизитов */}
   <Box mt={8}>
          <Text fontSize="sm" fontWeight="bold">
            Проверьте реквизиты Вашего Банка
          </Text>
          <Text fontSize="sm" fontWeight="bold" mb={4}>
            и при необходимости измените их:
          </Text>
          
          {/* Поля банковских реквизитов */}
          <FormControl mb={4}>
            <FormLabel fontSize="sm">БИК</FormLabel>
            <Input
            type="number"
              name="bic"
              value={formData.bic || ''}
              placeholder={user.bic || 'Введите БИК'}
              onChange={handleChange}
              bg={inputBgColor}
              disabled={!isEditing}
              borderColor={isEditing ? 'red.500' : 'gray.200'} // Устанавливаем красную обводку при редактировании
              borderWidth={isEditing ? '2px' : '1px'} // Увеличиваем ширину обводки при редактировании
              _disabled={{ opacity: 1 }} // Отключаем стилизацию disabled по умолчанию
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize="sm">Наименование</FormLabel>
            <Input
              name="bank_name"
              value={formData.bank_name || ''}
              placeholder={user.bank_name || ''}
              bg={inputBgColor}
              disabled // Поле остается неактивным
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize="sm">Кор счет</FormLabel>
            <Input
              name="correspondent_account"
              value={formData.correspondent_account || ''}
              placeholder={user.correspondent_account || ''}
              bg={inputBgColor}
              disabled // Поле остается неактивным
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize="sm">Расчетный счет</FormLabel>
            <Input
              name="account_number"
              value={formData.account_number || ''}
              placeholder={user.account_number || ''}
              onChange={handleChange}
              bg={inputBgColor}
              disabled
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize="sm">Дата открытия счета</FormLabel>
            <Input
              name="account_opening_date"
              value={formData.account_opening_date || ''}
              placeholder={user.account_opening_date || ''}
              onChange={handleChange}
              bg={inputBgColor}
              disabled 
            />
          </FormControl>

          {/* Кнопки подтверждения и изменения */}
          <Flex mt={4} justifyContent="flex-end">
            <Button                    
            fontSize="12px"
            bg="gray.300"
            padding="0.5rem 1rem"
            height="unset" onClick={handleEditClick}>
              Изменить реквизиты
            </Button>
          </Flex>
        </Box>

          </Box>
        </Box>
      </Grid>

      {/* Кнопка "Сохранить изменения" */}
      <Button
      position="fixed"
      bottom="20px"
      right="20px"
      colorScheme="blue"
      size="lg"
      fontSize="15px"
      visibility={isLoading || !isChanged ? 'hidden' : 'visible'}
      isLoading={isLoading} // Показываем встроенный спиннер во время загрузки
      onClick={handleSave}
    >
      Сохранить изменения
    </Button>

      {/* Блок с кнопками для договора и счета */}
  
        <SimpleGrid
        columns={{ base: 2, md: 3, lg: 3 }} // 2 колонки на мобильных, 3 на планшетах, 6 на десктопах
        mt={12}
        spacing={4} 
      >
    {/* Договор */}
    {formData.contract_name ? (
  <Flex
    align="center"
    bg="gray.100"
    width="100%"
    p={4}
    borderRadius="lg"
    boxShadow="sm"
  >
    <Icon as={MdDescription} color="gray.500" w={10} h={10} mr={4} />
    <Box>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="md" mb={2}>
        Договор
      </Text>
      <Flex>
        <Button
          as="a"
          size="xs"
          mr={1}
          width="23px"
          height="23px"
          colorScheme="blue"
          href={contractFileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon as={MdSearch} w={4} h={4} />
        </Button>
        <Button
          as="a"
          href={contractFileUrl}
          mr={1}
          width="23px"
          height="23px"
          download={formData.contract_name}
          size="xs"
          colorScheme="blue"
        >
          <Icon as={MdArrowDownward} w={4} h={4} />
        </Button>
      </Flex>
    </Box>
  </Flex>
) : user.client_type === "client" ? (
  <Flex direction="column"     bg="gray.100"
  width="100%"
  p={4}
  borderRadius="lg"
  boxShadow="sm">
      <Text color={textColorPrimary} textAlign="center" fontWeight="bold" fontSize="md" mb={2}>
        Загрузите Договор
      </Text>

      {/* Интеграция FileUpload */}
      <FileUpload
        setIsFileUploaded={setIsContractUploaded} // Обновление состояния загрузки
        uploadType="contracts" // Указываем тип загрузки
        onFileUploaded={(contractFileName) =>
          handleContractUploadComplete("contract_name", contractFileName)
        }
      />
  </Flex>
) : null}


{formData.invoice_name ? (
    <Flex
      align="center"
      bg="gray.100"
      p={4}
      borderRadius="lg"
      boxShadow="sm"
      width="100%"
    >
      <Icon as={MdDescription} color="gray.500" w={10} h={10} mr={4} />
      <Box>
        <Text color={textColorPrimary} fontWeight="bold" fontSize="md" mb={2}>
          Счет
        </Text>
        <Flex>
        <Button
          as="a"
          size="xs"
          mr={1}
          width="23px"
          height="23px"
          colorScheme="blue"
          href={invoiceFileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon as={MdSearch} w={4} h={4} />
        </Button>
        <Button
          as="a"
          href={invoiceFileUrl}
          mr={1}
          width="23px"
          height="23px"
          download={formData.invoice_name}
          size="xs"
          colorScheme="blue"
        >
          <Icon as={MdArrowDownward} w={4} h={4} />
        </Button>
      </Flex>
      </Box>
    </Flex>
) : user.client_type === "client" ? (
    <Flex direction="column"     bg="gray.100"
    width="100%"
    p={4}
    borderRadius="lg"
    boxShadow="sm">
        <Text color={textColorPrimary} textAlign="center" fontWeight="bold" fontSize="md" mb={2}>
          Загрузите Счет
        </Text>
  
        {/* Интеграция FileUpload */}
        <FileUpload
          setIsFileUploaded={setIsInvoiceUploaded} // Обновление состояния загрузки
          uploadType="invoice" // Указываем тип загрузки
          onFileUploaded={(invoiceFileName) =>
            handleInvoiceUploadComplete("invoice_name", invoiceFileName)
          }
        />
    </Flex>
) : null}

    {formData.type === "export" && (
      <>
      
      {formData.card_name ? (
        <Flex
          align="center"
          bg="gray.100"
          p={4}
          borderRadius="lg"
          boxShadow="sm"
          width="100%"
        >
          <Icon as={MdDescription} color="gray.500" w={10} h={10} mr={4} />
          <Box>
            <Text color={textColorPrimary} fontWeight="bold" fontSize="md" mb={2}>
              Карточка компании
            </Text>
            <Flex>
        <Button
          as="a"
          size="xs"
          mr={1}
          width="23px"
          height="23px"
          colorScheme="blue"
          href={cardFileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon as={MdSearch} w={4} h={4} />
        </Button>
        <Button
          as="a"
          href={cardFileUrl}
          mr={1}
          width="23px"
          height="23px"
          download={formData.card_name}
          size="xs"
          colorScheme="blue"
        >
          <Icon as={MdArrowDownward} w={4} h={4} />
        </Button>
      </Flex>
          </Box>
        </Flex>
) : user.client_type === "client" ? (
    <Flex direction="column"     bg="gray.100"
    width="100%"
    p={4}
    borderRadius="lg"
    boxShadow="sm">
        <Text color={textColorPrimary} textAlign="center" fontWeight="bold" fontSize="md" mb={2}>
          Загрузите Карточку компании
        </Text>
  
        {/* Интеграция FileUpload */}
        <FileUpload
          setIsFileUploaded={setIsCompanyCardUploaded} // Обновление состояния загрузки
          uploadType="contracts" // Указываем тип загрузки
          onFileUploaded={(cardFileName) =>
            handleCompanyCardUploadComplete("card_name", cardFileName)
          }
        />
    </Flex>
) : null}
       {formData.specialization_name ? (
        <Flex
          align="center"
          bg="gray.100"
          p={4}
          borderRadius="lg"
          boxShadow="sm"
          width="100%"
        >
          <Icon as={MdDescription} color="gray.500" w={10} h={10} mr={4} />
          <Box>
            <Text color={textColorPrimary} fontWeight="bold" fontSize="md" mb={2}>
              Специализация
            </Text>
            <Flex>
        <Button
          as="a"
          size="xs"
          mr={1}
          width="23px"
          height="23px"
          colorScheme="blue"
          href={specializationFileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon as={MdSearch} w={4} h={4} />
        </Button>
        <Button
          as="a"
          href={specializationFileUrl}
          mr={1}
          width="23px"
          height="23px"
          download={formData.specialization_name}
          size="xs"
          colorScheme="blue"
        >
          <Icon as={MdArrowDownward} w={4} h={4} />
        </Button>
      </Flex>
          </Box>
        </Flex>
) : user.client_type === "client" ? (
    <Flex direction="column"     bg="gray.100"
    width="100%"
    p={4}
    borderRadius="lg"
    boxShadow="sm">
        <Text color={textColorPrimary} textAlign="center" fontWeight="bold" fontSize="md" mb={2}>
          Загрузите Специализацию
        </Text>
  
        {/* Интеграция FileUpload */}
        <FileUpload
          setIsFileUploaded={setIsSpecializationUploaded} // Обновление состояния загрузки
          uploadType="specialization" // Указываем тип загрузки
          onFileUploaded={(specializationFileName) =>
            handleSpecializationUploadComplete("specialization_name", specializationFileName)
          }
        />
    </Flex>
) : null}
    {formData.rucard_name ? (
        <Flex
          align="center"
          bg="gray.100"
          p={4}
          borderRadius="lg"
          boxShadow="sm"
          width="100%"
        >
          <Icon as={MdDescription} color="gray.500" w={10} h={10} mr={4} />
          <Box>
            <Text color={textColorPrimary} fontWeight="bold" fontSize="md" mb={2}>
              Карточка компании в РФ
            </Text>
            <Flex>
        <Button
          as="a"
          size="xs"
          mr={1}
          width="23px"
          height="23px"
          colorScheme="blue"
          href={rucardFileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon as={MdSearch} w={4} h={4} />
        </Button>
        <Button
          as="a"
          href={rucardFileUrl}
          mr={1}
          width="23px"
          height="23px"
          download={formData.rucard_name}
          size="xs"
          colorScheme="blue"
        >
          <Icon as={MdArrowDownward} w={4} h={4} />
        </Button>
      </Flex>
          </Box>
        </Flex>
) : user.client_type === "client" ? (
  <Flex direction="column"     bg="gray.100"
  width="100%"
  p={4}
  borderRadius="lg"
  boxShadow="sm">
      <Text color={textColorPrimary} textAlign="center" fontWeight="bold" fontSize="md" mb={2}>
        Загрузите карточку компании в РФ
      </Text>

      {/* Интеграция FileUpload */}
      <FileUpload
        setIsFileUploaded={setIsRussiaCompanyCardUploaded} // Обновление состояния загрузки
        uploadType="ru_card" // Указываем тип загрузки
        onFileUploaded={(rucardFileName) => handleRussiaCompanyCardUploadComplete("rucard_name", rucardFileName)} // Вызываем при успешной загрузке
      />
  </Flex>
) : null}
      </>
    )}

{order.status === "черновик" && order.invoice_name && order.contract_name && (
  <Flex direction="row" alignItems="center" justifyContent="center"
  width="100%">

<Button
          colorScheme="blue"
          size="md"
          height="55px"
          onClick={() => updateStatus('в обработке')}
        >
          Отправить в работу
        </Button>
        </Flex>
  )}

  </SimpleGrid>
{user.client_type === "agent" && (
 
    <Flex direction="row" wrap="wrap" gap={4} mt={8}>
      {/* Блок 1: Первичная проверка */}
      {order.status === "в обработке" && (
      <Box bg="gray.100" borderRadius="lg" p={4} w="180px">
        <Text fontSize="sm" fontWeight="bold" mb={4} h="40px">
          Первичная проверка
        </Text>
        <Flex direction="column" gap={1}>
        <Button
          colorScheme="green"
          size="sm"
          mb={2}
          onClick={() => updateStatus('верификация заявки')}
        >
          Принять
        </Button>
        <Button
          colorScheme="red"
          size="sm"
          onClick={() => updateStatus('отклонена')}
        >
          Отказать
        </Button>
        </Flex>
      </Box>
    )}
    {order.status !== "в обработке" && (
     <SimpleGrid 
     columns={{ base: 1, md: 2, lg: 4 }} // 1 столбец на мобильных, 2 на планшетах, 4 на десктопах
     spacing={4} // Расстояние между элементами
   >
      <Box bg="gray.100" borderRadius="lg" p={4}>
        <Text fontSize="sm" fontWeight="bold" mb={4} h="40px">
          Верификация заявки
        </Text>

        {order.control_1 ? (
                <Flex direction="column" align="center" justify="center" h="100px">
                <Icon as={MdCheckCircle} color="green.500" boxSize={6} mb={2} />
                <Text fontSize="md" color="green.500" textAlign="center" fontWeight="bold">
                  Проверка пройдена
                </Text>
              </Flex>
               ) : (
        <Flex direction="column" gap={1}>
        <Button
          colorScheme="green"
          size="sm"
          mb={2}
          onClick={() => updateStatus('валютный контроль')}
        >
          Проверено
        </Button>
        <Button
          colorScheme="blue"
          size="sm"
          mb={2}
          onClick={() => updateStatus('требуется доработка')}
        >
          Доработка
        </Button>
        <Button
          colorScheme="red"
          size="sm"
          onClick={() => updateStatus('отклонена')}
        >
          Отказать
        </Button>
        </Flex>
          )}
      </Box>
  

      <Box bg="gray.100" borderRadius="lg" p={4}>
        <Text fontSize="sm" fontWeight="bold" mb={4} h="40px">
          Валютный контроль
        </Text>
        {order.control_2 ? (
                <Flex direction="column" align="center" justify="center" h="100px">
                <Icon as={MdCheckCircle} color="green.500" boxSize={6} mb={2} />
                <Text fontSize="md" color="green.500" textAlign="center" fontWeight="bold">
                  Проверка пройдена
                </Text>
              </Flex>
               ) : (
        <Flex direction="column" gap={1}>
        <Button
          colorScheme="green"
          size="sm"
          mb={2}
          onClick={() => updateStatus('проверка службы безопасности')}
        >
          Проверено
        </Button>
        <Button
          colorScheme="blue"
          size="sm"
          mb={2}
          onClick={() => updateStatus('требуется доработка (валютный контроль)')}
        >
          Доработка
        </Button>
        <Button
          colorScheme="red"
          size="sm"
          onClick={() => updateStatus('отклонена')}
        >
          Отказать
        </Button>
        </Flex>
         )}
      </Box>

      <Box bg="gray.100" borderRadius="lg" p={4}>
        <Text fontSize="sm" fontWeight="bold" mb={4} h="40px">
          Служба безопасности
        </Text>
        {order.control_3 ? (
                <Flex direction="column" align="center" justify="center" h="100px">
                <Icon as={MdCheckCircle} color="green.500" boxSize={6} mb={2} />
                <Text fontSize="md" color="green.500" textAlign="center" fontWeight="bold">
                  Проверка пройдена
                </Text>
              </Flex>
               ) : (
        <Flex direction="column" gap={1}>
        <Button
          colorScheme="green"
          size="sm"
          mb={2}
          onClick={() => updateStatus('проверка службы финансового мониторинга')}
        >
          Проверено
        </Button>
        <Button
          colorScheme="blue"
          size="sm"
          mb={2}
          onClick={() => updateStatus('требуется доработка (служба безопасности)')}
        >
          Доработка
        </Button>
        <Button
          colorScheme="red"
          size="sm"
          onClick={() => updateStatus('отклонена')}
        >
          Отказать
        </Button>
        </Flex>
         )}
      </Box>

      <Box bg="gray.100" borderRadius="lg" p={4}>
        <Text fontSize="sm" fontWeight="bold" mb={4} h="40px">
          Финансовый мониторинг
        </Text>
        {order.control_4 ? (
                <Flex direction="column" align="center" justify="center" h="100px">
                <Icon as={MdCheckCircle} color="green.500" boxSize={6} mb={2} />
                <Text fontSize="md" color="green.500" textAlign="center" fontWeight="bold">
                  Проверка пройдена
                </Text>
              </Flex>
               ) : (
        <Flex direction="column" gap={1}>
        <Button
          colorScheme="green"
          size="sm"
          mb={2}
          onClick={() => updateStatus('согласование договора')}
        >
          Проверено
        </Button>
        <Button
          colorScheme="blue"
          size="sm"
          mb={2}
          onClick={() => updateStatus('требуется доработка (служба финансового мониторинга)')}
        >
          Доработка
        </Button>
        <Button
          colorScheme="red"
          size="sm"
          onClick={() => updateStatus('отклонена')}
        >
          Отказать
        </Button>
        </Flex>
          )}
      </Box>
      </SimpleGrid>
        )}
    </Flex>
 )}

    </Box>
  );
};



export default OrderParams;
