import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom"; // Для получения токена из URL и навигации
import { UserContext } from "contexts/UserContext";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.jpg";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function CreateAccount() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userId, setUserId] = useState(null); // Состояние для userId
  const [isActive, setIsActive] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [tokenError, setTokenError] = useState(false); // Состояние для ошибки токена

  const toast = useToast(); // Инициализация toast
  const navigate = useNavigate(); // Для навигации на страницу регистрации
  const { createAccount, confirm } = useContext(UserContext); // Подключаем функции из контекста
  const { token } = useParams(); // Получаем токен из URL

  // Обработка подтверждения регистрации
 useEffect(() => {
    if (token) {
      confirm(token)
        .then((result) => {
          if (result) {
            const { client_id, is_active } = result;
            setUserId(client_id); // Устанавливаем userId
            setIsActive(is_active); // Устанавливаем is_active
            setTokenError(false);
          } else {
            setTokenError(true);
          }
        })
        .catch((error) => {
          console.error("Ошибка при подтверждении токена:", error.message);
          setTokenError(true);
        });
    }
  }, [token]);
  

  const toggleShowPassword = () => setShowPassword(!showPassword);

  // Функция проверки пароля
  const isPasswordValid = (password) => {
    const passwordRegex = /^.{8,}$/; // Минимальная длина 8 символов, любые символы
    return passwordRegex.test(password);
  };

  const handleSubmit = (e) => {
        e.preventDefault();
    const loginRegex = /^[a-zA-Z0-9_]+$/;
  
    if (!loginRegex.test(login)) {
      alert("Логин может содержать только латинские буквы, цифры и символ подчеркивания.");
      return;
    }
  
    if (password !== confirmPassword) {
      alert("Пароли не совпадают.");
      return;
    }

    if (!isPasswordValid(password)) {
      toast({
        title: "Неверный пароль",
        description:
          "Пароль должен быть длиной не менее 8 символов и содержать латинские буквы, одну заглавную, одну строчную, цифру и спецсимвол.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (password !== confirmPassword) {
      toast({
        title: "Ошибка",
        description: "Пароли не совпадают.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!userId) {
      toast({
        title: "Ошибка",
        description: "Не удалось получить userId.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    createAccount({ userId, login, password }); // Передача userId первым параметром в createAccount
    navigate("/auth/sign-in");
  };

  const handleRetryRegistration = () => {
    navigate("/auth/register"); // Перенаправить на страницу регистрации
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      {tokenError && !isActive ? (
        <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
        >
                      <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
               Ваш токен устарел
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Попробуйте пройти регистрацию повторно
            </Text>
          </Box>

          <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="200px"
              h="50"
              onClick={handleRetryRegistration}
            >
              Регистрация
            </Button>
        </Flex>
      ) : (
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Завершите создание аккаунта
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Введите логин и пароль для регистрации.
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl mb="24px">
              <FormLabel fontSize="sm" fontWeight="500" color={textColor}>
                Логин
              </FormLabel>
              <Input
                type="text"
                placeholder="Введите логин"
                variant="auth"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
            </FormControl>
            <FormControl mb="24px">
              <FormLabel fontSize="sm" fontWeight="500" color={textColor}>
                Пароль<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Введите пароль"
                  variant="auth"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement>
                  <Icon
                    as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={toggleShowPassword}
                    cursor="pointer"
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl mb="24px">
              <FormLabel fontSize="sm" fontWeight="500" color={textColor}>
                Подтвердите пароль<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Повторите пароль"
                  variant="auth"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              onClick={handleSubmit}
            >
              Зарегистрироваться
            </Button>
          </Flex>
        </Flex>
      )}
    </DefaultAuth>
  );
}

export default CreateAccount;
