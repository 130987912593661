
import React from 'react';

export default function WorkReportTemplate({ order, user }) {
  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      lineHeight: '1.6',
      color: '#333',
      border: '1px solid #ddd',
      borderRadius: '8px',
    },
    title: {
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center',
      fontSize: '22px',
      fontWeight: 'bold',
      marginBottom: '20px',
      textTransform: 'uppercase',
    },
    date: {
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center',
      fontSize: '16px',
      fontStyle: 'italic',
      marginBottom: '20px',
    },
    paragraph: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      textAlign: 'left',
      marginBottom: '10px',
    },
    table: {
      fontFamily: 'Arial, sans-serif',
      width: '100%',
      borderCollapse: 'collapse',
      marginBottom: '20px',
    },
    th: {
      border: '1px solid #ddd',
      fontSize: '14px',
      padding: '8px',
      backgroundColor: '#f9f9f9',
      fontWeight: 'bold',
    },
    td: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      border: '1px solid #ddd',
      padding: '8px',
      textAlign: 'center',
    },
    signatures: {
    fontFamily: 'Arial, sans-serif',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '40px',
    },
    signatureBlock: {
      textAlign: 'center',
      width: '45%',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>АКТ-ОТЧЕТ АГЕНТА об ОСУЩЕСТВЛЕННЫХ ПЛАТЕЖАХ</h1>
      <p style={styles.date}>«» ___ 2024 г.</p>

      <p style={styles.paragraph}>
        « », именуемое в дальнейшем «Агент», в лице, действующего на основании, составило настоящий Акт-Отчет агента по Агентскому договору от «00» _______ 2024 г. № 00/00/2024-Т-0 (далее – Договор) о том, что:
      </p>

      <p style={styles.paragraph}>
        Во исполнение пункта 2.1 Договора Агент в период с «» ___ 2024 г. по «» ____ 2024 г. осуществил по поручению «», именуемый в дальнейшем «Принципал», оплату следующих Денежных требований:
      </p>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>№</th>
            <th style={styles.th}>Наименование Поставщика</th>
            <th style={styles.th}>Дата и Номер Контракта</th>
            <th style={styles.th}>Дата и Номер Поручения</th>
            <th style={styles.th}>Наименование, Дата и Номер документа, подтверждающего Денежное требование</th>
            <th style={styles.th}>Дата Платежа</th>
            <th style={styles.th}>Сумма Платежа</th>
            <th style={styles.th}>Валюта Платежа</th>
            <th style={styles.th}>Комиссия за Платеж</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styles.td}>1</td>
            <td style={styles.td}></td>
            <td style={styles.td}></td>
            <td style={styles.td}></td>
            <td style={styles.td}></td>
            <td style={styles.td}></td>
            <td style={styles.td}></td>
            <td style={styles.td}></td>
            <td style={styles.td}></td>
          </tr>
          {/* Добавьте дополнительные строки по мере необходимости */}
        </tbody>
      </table>

      <p style={styles.paragraph}>Итого: Сумма возмещения составила 00 руб. 00 коп.</p>
      <p style={styles.paragraph}>Комиссия за платеж составила 00 руб. 00 коп.</p>

      <p style={styles.paragraph}>
        Настоящий Акт-Отчет агента составлен в двух экземплярах, по одному для Принципала и Агента. Принципал, имеющий возражения по Акту-Отчету агента, должен сообщить о них Агенту в срок, предусмотренный Договором.
      </p>

      <div style={styles.signatures}>
        <div style={styles.signatureBlock}>
          <p>Агент:</p>
          <p>От имени Агента:</p>
          <p>_______________________</p>
        </div>
        <div style={styles.signatureBlock}>
          <p>Принципал:</p>
          <p>От имени Принципала:</p>
          <p>_______________________</p>
        </div>
      </div>
    </div>
  );
}
