import React, { useContext } from "react";
// Chakra imports
import { Box, Grid, Flex, Icon, Text, Spinner } from "@chakra-ui/react";

// Custom components
import IconBox from "components/icons/IconBox";
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Agent from "views/admin/profile/components/Agent";
import Agreement from "views/admin/profile/components/Agreement";

// Contexts
import { UserContext } from "contexts/UserContext";

// Icons
import { MdPerson } from "react-icons/md";

// Assets

export default function Overview() {
  const { user, sendOrganizationData, agent } = useContext(UserContext); // Добавляем isLoading из UserContext
  


  const formatDate = (dateString) => {
    if (!dateString) return ''; // Возвращаем пустую строку, если дата отсутствует
    const date = new Date(dateString);
    if (isNaN(date)) return ''; // Проверяем, является ли дата валидной
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };


  // Если пользователь не авторизован, показываем соответствующее сообщение
  if (!user) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Text fontSize="lg" fontWeight="bold">
          Пользователь не авторизован.
        </Text>
      </Flex>
    );
  }



  return (
    <>
      <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px">
        <IconBox
          w="56px"
          h="56px"
          bg="white"
          float="left"
          icon={<Icon w="32px" h="32px" as={MdPerson} color="navy.500" />}
        />
        <Text
          pl="20px"
          fontSize="30px"
          fontWeight="bold"
          lineHeight="140%"
        >
          Личный кабинет
        </Text>
      </Flex>

      <Box>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1.75fr 1.2fr 1.3fr"
          }}
          gap={{ base: "20px" }}
        >
                    <Banner
            user={user}
            agent={agent}
            formatDate={formatDate}
          />
                             <Agent
            user={agent}
          />
          <Agreement />
        </Grid>
        <General user={user} sendOrganizationData={sendOrganizationData} />
      </Box>
    </>
  );
}
