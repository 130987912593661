// Chakra imports
import { Box, Flex, Text, Icon, Spinner } from "@chakra-ui/react";
import ComplexTable from "views/admin/orders/components/ComplexTable";
import React, { useContext } from "react";
import { DataContext } from "contexts/DataContext";
import { UserContext } from "contexts/UserContext"; // Убедитесь, что UserContext подключен
import IconBox from "components/icons/IconBox";
import { MdDescription } from "react-icons/md";

export default function Orders() {
  const { orders, isLoading: isOrdersLoading } = useContext(DataContext);
  // Если данные загружаются, отображаем спиннер
  if (isOrdersLoading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <>
      <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px">
        <IconBox
          w="56px"
          h="56px"
          bg="white"
          float="left"
          icon={<Icon w="32px" h="32px" as={MdDescription} color="navy.500" />}
        />
        <Text pl="20px" fontSize="30px" fontWeight="bold" lineHeight="140%">
          Мои заявки
        </Text>
      </Flex>
      <Box>
        <ComplexTable tableData={orders} />
      </Box>
    </>
  );
}
