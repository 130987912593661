import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Text,
    Flex,
    Spinner,
  } from "@chakra-ui/react";
  import React from "react";
  
  export default function EditModal({ isOpen, onClose, editedValues, handleInputChange, handleSubmit, isSubmitting }) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Редактировать данные</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={4}>
              <Text>Ваш ИНН</Text>
              <Input
                placeholder="Введите ваш ИНН"
                value={editedValues.INN}
                onChange={(e) => handleInputChange("INN", e.target.value)}
              />
              <Text>БИК вашего банка</Text>
              <Input
                placeholder="Введите БИК банка"
                value={editedValues.BIC}
                onChange={(e) => handleInputChange("BIC", e.target.value)}
              />
              <Text>Ссылка на ваш сайт</Text>
              <Input
                placeholder="Введите ссылку на сайт"
                value={editedValues.website}
                onChange={(e) => handleInputChange("website", e.target.value)}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit} isDisabled={isSubmitting}>
              {isSubmitting ? <Spinner size="sm" /> : "Отправить"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  