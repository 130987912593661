import { useState } from "react";
import { Text, useColorModeValue, Button, ButtonGroup, Flex, Icon, Box } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import FileUpload from "./Upload.js";
import { AiOutlineFileText } from 'react-icons/ai';

const CompactDataTable = () => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isApproveVisible, setIsApproveVisible] = useState(false);
  const bg = useColorModeValue("white", "navy.700");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const handleEditClick = () => {
    setIsEditVisible(true);
    setIsApproveVisible(false);
  };
  
  const handleApproveClick = () => {
    setIsApproveVisible(true);
    setIsEditVisible(false);
  };

  return (
    <Card bg={bg} mb={{ base: "0px", lg: "20px" }} align="center" p="35px">
      <Flex alignItems="center" direction="column" mb="20px" position="relative">
        <Text color={textColorPrimary} fontWeight="bold" fontSize="xl">
          Агентский договор
        </Text>
        <Text color={textColorPrimary} fontSize="sm" maxWidth="270px" mb={4}>
              Скачайте, подпишите и отправьте нам его в электронном виде
            </Text>
        <Box position="relative" mt="20px">
          <Icon as={AiOutlineFileText} fontSize="84px" color="secondaryGray.500" />
          <Button
            colorScheme="blue"
            position="absolute"
            top="60%"
            left="50%"
            transform="translate(-50%, -50%)"
            fontSize="12px"
            padding="0.5rem 1rem"
            height="unset"
          >
            Скачать
          </Button>
        </Box>

        <ButtonGroup spacing={4} mt={4}>
          <Button
            fontSize="13px"
            padding="0.5rem 1rem"
            minHeight="unset"
            marginTop="12px"
            colorScheme="gray"
            variant="outline"
            onClick={handleApproveClick}
          >
            Согласовать
          </Button>
          <Button
            fontSize="13px"
            padding="0.5rem 1rem"
            minHeight="unset"
            marginTop="12px"
            colorScheme="gray"
            variant="outline"
            onClick={handleEditClick}
          >
            Изменить
          </Button>
        </ButtonGroup>

        {isEditVisible && (
    
              <Box maxWidth="270px">
            <Text color={textColorPrimary} fontSize="sm" mt={4} mb={4}>
              Приложите свою версию договора
            </Text>
        
              <FileUpload/>
            </Box>
   
        )}
                {isApproveVisible && (
    
    <Box maxWidth="270px">
  <Text color={textColorPrimary} fontSize="sm" mt={4} mb={4}>
    Загрузите подписанную версию договора
  </Text>

    <FileUpload />
  </Box>

)}
      </Flex>
    </Card>
  );
};

export default CompactDataTable;
