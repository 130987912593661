import React, { useState } from "react";
import { Button, Flex, Table, Text, Icon, Thead, Tbody, Tr, Th, Td, Input, IconButton, Box } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import { MdHandshake } from "react-icons/md";

const EditableTable = () => {
  const [data, setData] = useState(
    Array(4).fill({
      name: "",
      country: "",
      bank: "",
      currencies: "",
      activity_type: "",
      licenses: "",
      maintenance_cost: "",
      counterparties: "",
      cash_inflow: "",
      cash_outflow: "",
      cash_balance: "",
      comments: "",
      company_card: "",
    })
  );

  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (index, field, value) => {
    const newData = [...data];
    newData[index] = { ...newData[index], [field]: value };
    setData(newData);
    setIsChanged(true);
  };

  const handleSave = () => {
    setIsLoading(true);
    setTimeout(() => {
      console.log("Сохраненные данные:", data);
      setIsLoading(false);
      setIsChanged(false);
    }, 1000);
  };

  const addRow = () => {
    setData([
      ...data,
      {
        name: "",
        country: "",
        bank: "",
        currencies: "",
        activity_type: "",
        licenses: "",
        maintenance_cost: "",
        counterparties: "",
        cash_inflow: "",
        cash_outflow: "",
        cash_balance: "",
        comments: "",
        company_card: "",
      },
    ]);
  };

  return (
    <>
      <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px">
        <IconBox
          w="56px"
          h="56px"
          bg="white"
          float="left"
          icon={<Icon w="32px" h="32px" as={MdHandshake} color="navy.500" />}
        />
        <Text pl="20px" fontSize="30px" fontWeight="bold" lineHeight="140%">
          Мои организации
        </Text>
      </Flex>
    
    <Card w="100%" p={{ base: "20px", md: "40px" }}>
      <Box
        position="relative"
        overflowX="auto"
        bg="#fafcfe"
        borderRadius="10px"
        minHeight="70vh"
        p="10px"
      >
        <Table variant="unstyled" size="sm">
          <Thead>
            <Tr>
              <Th>Наименование</Th>
              <Th>Страна</Th>
              <Th>Банк</Th>
              <Th>Валюты</Th>
              <Th>Вид деятельности</Th>
              <Th>Лицензии</Th>
              <Th>Стоимость содержания</Th>
              <Th>Контрагенты</Th>
              <Th>Cash inflow</Th>
              <Th>Cash outflow</Th>
              <Th>Остаток денежных средств</Th>
              <Th>Комментарии</Th>
              <Th>Карточка компании (Word)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => (
              <Tr key={index}>
                {Object.keys(row).map((field) => (
                  <Td key={field} p="2px">
                    <Input
                      value={row[field]}
                      onChange={(e) => handleInputChange(index, field, e.target.value)}
                      bg="white"
                      fontSize="sm"
                    />
                  </Td>
                ))}
              </Tr>
            ))}
            {/* Нижняя строка с кнопкой */}
            <Tr>
              <Td colSpan={13} textAlign="left" p="10px">
                <IconButton
                  icon={<AddIcon />}
                  colorScheme="green"
                  aria-label="Добавить строку"
                  borderRadius="full"
                  onClick={addRow}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Button
        position="fixed"
        bottom="20px"
        right="20px"
        colorScheme="blue"
        size="lg"
        fontSize="15px"
        visibility={isLoading || !isChanged ? "hidden" : "visible"}
        isLoading={isLoading}
        onClick={handleSave}
      >
        Сохранить изменения
      </Button>
    </Card>
    </>
  );
};

export default EditableTable;
