import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Flex,
  Icon,
  Tab,
  TabPanel,
  Step,
  StepIndicator,
  StepDescription,
  StepNumber,
  StepSeparator,
  Stepper,
  StepStatus,
  StepTitle,
  Spinner,
  useToast
} from "@chakra-ui/react";
import { DataContext } from "contexts/DataContext";
import { UserContext } from "contexts/UserContext";
import { useParams } from "react-router-dom";
import IconBox from "components/icons/IconBox";
import { MdOutlinePendingActions, MdCheckCircle } from "react-icons/md";

import Card from "components/card/Card.js";
import OrderParams from "views/admin/order/panels/OrderParams";
import Agreement from "views/admin/order/panels/Agreement";
import TransferInstruction from "views/admin/order/panels/TransferInstruction";
import WorkReport from "views/admin/order/panels/WorkReport";
import ChatPage from "views/admin/order/panels/ChatPage";

const tabAccessRules = {
  0: ["черновик", "в обработке", "верификация заявки", "требуется доработка", "валютный контроль", "проверка службой безопасности", "требуется доработка (валютный контроль)", "требуется доработка (служба безопасности)", "проверка службы финансового мониторинга", "требуется доработка (служба финансового мониторинга)"],
  1: ["согласование договора", "договор на согласовании у агента", "требуется доработка договора", "согласование стоимости перевода", "согласование стоимости перевода агентом", "согласование стоимости перевода клиентом", "согласование поручения на перевод", "согласование стоимости перевода клиентом", "на согласовании у агента","согласование акта", "акт на согласовании агента", "требуется доработка акта"],
  2: ["согласование стоимости перевода", "согласование стоимости перевода агентом", "согласование стоимости перевода клиентом", "согласование поручения на перевод", "согласование стоимости перевода клиентом", "на согласовании у агента","согласование акта", "акт на согласовании агента", "требуется доработка акта"],
  3: ["согласование акта", "акт на согласовании агента", "требуется доработка акта"],
};

const statuses = [
  { title: "Черновик", description: "Подготовка заявки", status: "черновик", supportedStatuses: ["черновик"] },
  { title: "Новая заявка", description: "Заявка в процессе", status: "в обработке", supportedStatuses: ["в обработке"] },
  { 
    title: "Верификация заявки", 
    description: "Проверка данных", 
    status: "верификация", 
    supportedStatuses: ["верификация заявки", "требуется доработка заявки", "валютный контроль", "проверка службой безопасности", "требуется доработка (валютный контроль)", "требуется доработка (служба безопасности)", "проверка службы финансового мониторинга", "требуется доработка (служба финансового мониторинга)"] 
  },
  { title: "Агентский договор", description: "Согласование и подписание", status: "согласование", supportedStatuses: ["согласование договора", "договор на согласовании у агента", "требуется доработка договора"] },
  { title: "Поручение на перевод", description: "Согласование и подписание", status: "согласование поручения", supportedStatuses: ["согласование стоимости перевода", "согласование стоимости перевода агентом", "согласование стоимости перевода клиентом", "согласование поручения на перевод", "согласование стоимости перевода клиентом", "на согласовании у агента"] },
  { title: "Исполнение платежа", description: "Перечисление денег", status: "подтвердите платеж", supportedStatuses: ["подтвердите платеж", "требуется доработка", "ожидание оплаты клиентом", "оплата клиентом подтверждена. ожидайте оплаты агентом", "платеж проведён"] },
  { title: "Акт выполненных работ", description: "Подписание акта", status: "согласование", supportedStatuses: ["согласование акта", "акт на согласовании агента", "требуется доработка акта"] },
  { title: "Заявка завершена", description: "Заявка исполнена или отклонена", status: "исполнена", supportedStatuses: ["исполнена", "отклонена"] },
];

export default function OrderDetails() {
  const { fetchOrderById, updateOrder} = useContext(DataContext);
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useToast(); // Хук для работы с тостами
  useEffect(() => {
    const loadOrder = async () => {
      const fetchedOrder = await fetchOrderById(id);
      setOrder(fetchedOrder);
    };
    loadOrder();
  }, [id, fetchOrderById]);

  if ( !order) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  const isTabAccessible = (tabIndex) => {
    const allowedStatuses = tabAccessRules[tabIndex] || [];
    return allowedStatuses.includes(order.status);
  };
  const currentStatus = order.status;


  const updateStatus = async (status) => {
    if (!order || !order.id) {
      return;
    }
    try {
      await updateOrder(order.id, { ...order, status });
      const updatedOrder = { ...order, status };
    setOrder(updatedOrder); // Передаем новый статус
      toast({
        title: 'Статус обновлен!',
        description: `Новый статус: ${status}.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } catch (error) {
      console.error('Ошибка изменения статуса:', error);
      toast({
        title: 'Ошибка!',
        description: 'Не удалось изменить статус. Попробуйте снова.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Возвращаем пустую строку, если дата отсутствует
    const date = new Date(dateString);
    if (isNaN(date)) return ''; // Проверяем, является ли дата валидной
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const title = `Заявка на ${
    order.type === "export" ? "отправку платежа" : "получение платежа"
  } № ${order.id} от ${formatDate(order.datum)}`;

  return (
    <>
      <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px">
        <IconBox
          w="56px"
          h="56px"
          bg="white"
          float="left"
          icon={<Icon w="32px" h="32px" as={MdOutlinePendingActions} color="navy.500" />}
        />
        <Flex pl="20px" direction="column">
          <Heading fontSize="27px" lineHeight="125%">
            {title}
          </Heading>
          <Text fontSize="12px" fontWeight="bold" color="gray.500" textTransform="uppercase">
            Статус: <Text display="inline-block" color="green.500">{order.status}</Text>
          </Text>
        </Flex>
      </Flex>

      <Card w="100%" p={{ base: "20px", md: "40px" }}>
        <Flex direction={{ base: "column", lg: "row" }} justify="space-between">
          <Box flex="1" pr={{ base: "0", lg: "20px" }}>
            <Tabs mt={5} index={activeIndex} onChange={(index) => setActiveIndex(index)}>
              <TabList>
                <Tab fontSize="15px">Параметры заявки</Tab>
                <Tab fontSize="15px" isDisabled={!isTabAccessible(1)}>Агентский договор</Tab>
                <Tab fontSize="15px" isDisabled={!isTabAccessible(2)}>Поручение на перевод</Tab>
                <Tab fontSize="15px" isDisabled={!isTabAccessible(3)}>Акт выполненных работ</Tab>
                <Tab fontSize="15px">Чат</Tab>
              </TabList>
              <TabPanels mt={8}>
                <TabPanel>
                  <OrderParams order={order} user={user} updateStatus={updateStatus} />
                </TabPanel>
              
                  <TabPanel>
                    <Agreement order={order} user={user} updateStatus={updateStatus} />
                  </TabPanel>
           
            
                  <TabPanel>
                    <TransferInstruction order={order} user={user} updateStatus={updateStatus} />
                  </TabPanel>
         
                  <TabPanel>
                    <WorkReport order={order} user={user} updateStatus={updateStatus} />
                  </TabPanel>
         
                <TabPanel>
                <ChatPage orderId={order.id} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
          <Box width="300px" p={4} bg="gray.100" borderRadius="md">
            <Text fontSize="md" mt="10px" mb="25px" fontWeight="bold">
              Этапы работы с заявкой
            </Text>
            <Stepper
  index={statuses.findIndex((s) => s.supportedStatuses.includes(currentStatus))}
  colorScheme="blue"
  orientation="vertical"
  align="flex-start"
  ml={{ base: "0", lg: "20px" }}
  mt={{ base: "20px", lg: "0" }}
>
  {statuses.map((status, index) => {
    // Проверяем, относится ли текущий статус к этому шагу
    const isCurrentStep = status.supportedStatuses.includes(currentStatus);

    return (
      <Step key={index} orientation="horizontal" alignItems="center">
        <StepIndicator>
          <StepStatus
            complete={<MdCheckCircle color="green.500" />}
            incomplete={<StepNumber />}
            active={<StepNumber />}
          />
        </StepIndicator>
        <Box flexShrink="0">
          <StepTitle>{status.title}</StepTitle>
          <StepDescription maxWidth="25ch">
            {/* Если это текущий шаг, отображаем текущий статус */}
            {isCurrentStep ? currentStatus : status.description}
          </StepDescription>
        </Box>
        <StepSeparator
            maxHeight="calc(100% - 2rem  + 6px)!important"
            top="calc(2rem + 11px)!important"
        
        />
      </Step>
    );
  })}
</Stepper>

          </Box>
        </Flex>
      </Card>
    </>
  );
}
