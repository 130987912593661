import React, { useState } from 'react';
import { Text, useColorModeValue, Box, Flex, useToast, Button, IconButton, HStack, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Tooltip } from '@chakra-ui/react';
import WorkReportTemplate from 'views/admin/order/documents/WorkReportTemplate';
import FileUpload from "views/admin/profile/components/Upload";
import { FaDownload, FaEye, FaCheck, FaEdit } from 'react-icons/fa';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';

const WorkReport = ({ order, user,  }) => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isApproveVisible, setIsApproveVisible] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const handleEditClick = () => {
    setIsEditVisible(true);
    setIsApproveVisible(false);
  };
  
  const handleApproveClick = () => {
    setIsApproveVisible(true);
    setIsEditVisible(false);
  };

  const handleSubmitClick = () => {

  };

  const handleConfirmation = () => {
    // Показать тост с сообщением об успешном выполнении
    toast({
      title: 'Заявка успешно исполнена',
      status: 'success',
      duration: 3000, // Длительность отображения в миллисекундах
      isClosable: true,
    });

    // Переход на страницу admin/orders
    navigate('/admin/orders/');
  };

  const cssStyles = `
  .container {
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    color: #333;
  }
  .title {
    font-family: Arial, sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .sectionTitle {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .subSectionTitle {
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .paragraph {
    font-family: Arial, sans-serif;
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .listItem {
    font-family: Arial, sans-serif;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  .signaturesItem {
    width: 50%;
    vertical-align: top;
    padding: 0 10px;
  }

`;

  // Функция для скачивания содержимого AgreementTemplate как .doc
  const handleDownload = () => {
    const content = ReactDOMServer.renderToStaticMarkup(<WorkReportTemplate user={user} />);
    const element = document.createElement("a");
    const fileContent = `
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        <head>
          <meta charset='utf-8'>
          <title>Документ</title>
          <style>${cssStyles}</style>
        </head>
        <body>${content}</body>
      </html>`;
    const blob = new Blob(['\ufeff', fileContent], { type: 'application/msword' });
    const url = URL.createObjectURL(blob);
    element.href = url;
    element.download = "agreement_template.doc";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Flex direction="column" align="center">
      {/* Область с вертикальной прокруткой */}
      <Box
        position="relative"
        h="600px"
        w="95%"
        overflowY="auto"
        borderWidth="1px"
        borderRadius="md"
        ml="auto"
        mr="auto"
        p={3}
        pt={8}
        mb={4}
        _hover={{
          '> .icon-buttons': { opacity: 1, visibility: 'visible' }
        }}
      >
        <Box className="icon-buttons" position="absolute" top="0" right="0" p={2} opacity={0} visibility="hidden" transition="opacity 0.2s, visibility 0.2s">
          <HStack spacing={2}>
            <Tooltip label="Скачать" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaDownload />} aria-label="Скачать" onClick={handleDownload} />
            </Tooltip>
            <Tooltip label="Посмотреть" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaEye />} aria-label="Посмотреть" onClick={onOpen} />
            </Tooltip>
          </HStack>
        </Box>
        <WorkReportTemplate user={user} />
      </Box>

      <Box m="0 auto 20px">
          <HStack spacing={2}>
            <Tooltip label="Скачать" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaDownload />} aria-label="Скачать" onClick={handleDownload} />
            </Tooltip>
            <Tooltip label="Посмотреть" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaEye />} aria-label="Посмотреть" onClick={onOpen} />
            </Tooltip>
          </HStack>
        </Box>


      <Flex justifyContent="center" gap={4} mt={4}>
+  
      <Button
        leftIcon={<FaEdit />}
        colorScheme="gray"
        variant="outline"
        width="150px"
        onClick={handleEditClick}
      >
        Изменить
      </Button>



      <Button
        leftIcon={<FaCheck />}
        colorScheme="blue"
        width="150px"
        onClick={handleApproveClick}
      >
        Согласовать
      </Button>


    </Flex>

    {isApproveVisible && (

<Box maxWidth="470px">
<Text color={textColorPrimary} fontSize="sm" fontWeight="bold" textAlign="center" mt={4} mb={4}>
Загрузите подписанную версию договора
</Text>

<FileUpload setIsFileUploaded={setIsFileUploaded} />

<Button
        colorScheme="blue"
        width="100%"
        onClick={handleConfirmation}
        isDisabled={!isFileUploaded}
      >
        Отправить
      </Button>
</Box>

)}

{isEditVisible && (
    
    <Box maxWidth="470px">
  <Text color={textColorPrimary} fontSize="sm" fontWeight="bold" textAlign="center" mt={4} mb={4}>
    Приложите свою версию договора
  </Text>

    <FileUpload setIsFileUploaded={setIsFileUploaded}/>
    <Button
        colorScheme="blue"
        width="100%"
        onClick={handleSubmitClick}
        isDisabled={!isFileUploaded}
      >
        Отправить
      </Button>


  </Box>

  

)} 


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="90%" maxWidth="900px" pt={8}>
          <ModalCloseButton />
          <ModalBody>
            <WorkReportTemplate user={user} />
          </ModalBody>
          <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleDownload}>Скачать</Button>
            <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>Закрыть</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default WorkReport;
