import React, { useState } from "react";
import { Box, Input, IconButton, HStack } from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { sendMessage } from "contexts/ChatContext";

const MessageInput = ({ chatId, userId, userName }) => {
  const [message, setMessage] = useState("");


  const handleSend = async () => {
    if (!message.trim()) return; // Проверка на пустое сообщение
  
    const messageData = {
        content: message, // Текст сообщения
        chat_id: chatId, // Идентификатор чата
        userId: userId, // Идентификатор клиента
        user_name: userName,
    };
  
    try {
      const result = await sendMessage(chatId, messageData); // Отправляем сообщение через контекст
      console.log("Message sent successfully:", result);
      setMessage(""); // Очищаем поле ввода
    } catch (error) {
      console.error("Ошибка отправки сообщения:", error);
    }
  };
  

  return (
    <Box bg="white" marginTop="15px">
      <HStack spacing={3}>


        {/* Поле ввода текста */}
        <Input
          placeholder="Введите сообщение"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          flex="1"
        />

        {/* Кнопка отправки */}
        <IconButton
          icon={<FiSend />}
          colorScheme="blue"
          aria-label="Отправить сообщение"
          onClick={handleSend}
          borderRadius="0.375rem"
          w="100px"
        />
      </HStack>
    </Box>
  );
};

export default MessageInput;
