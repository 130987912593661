import React from 'react';

export default function AgreementTemplate({ order }) {

  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '1000px',
      margin: '0 auto',
      padding: '20px',
      lineHeight: '1.6',
      color: '#333',
    },
    title: {
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '10px',
      textTransform: 'uppercase',
    },
    sectionTitle: {
       fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    subSectionTitle: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '15px',
      marginBottom: '5px',
    },
    paragraph: {
      fontFamily: 'Arial, sans-serif',  
      textAlign: 'left',
      fontSize: '14px',
      marginBottom: '10px',
    },
    listItem: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      marginBottom: '5px',
    },
    table: {
        width: '100%',
        textAlign: 'left',
        borderСollapse: 'collapse',
      },
    signaturesItem: {
        width: '50%',
        verticalAlign: 'top',
        padding: '0 10px',
      }
  };

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1; // Месяцы начинаются с 0, поэтому добавляем 1
  const year = today.getFullYear();

  function formatToSurnameAndInitials(fullName) {
    if (!fullName) {
      return ''; // Возвращаем пустую строку, если `name` отсутствует
  }
    const [lastName, firstName, middleName] = fullName.split(" ");
    if (!lastName || !firstName) return fullName; // На случай некорректного ввода
    
    const initials = `${firstName[0]}.${middleName ? middleName[0] + '.' : ''}`;
    return `${lastName} ${initials}`;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>АГЕНТСКИЙ ДОГОВОР № 00/00/2024-Т-0</h1>
      <p style={styles.paragraph}>г. Москва, «{ day }» { month } { year } г.</p>

      <p style={styles.paragraph}>
      ______________________________________________________, именуемое в дальнейшем
        «Агент», в лице Директора _____________________________, действующего на основании Учредительного Договора от
        10.03.2023, с одной стороны, и { order.full_company_name }, именуемое в дальнейшем
        «Принципал», в лице генерального директора, действующего на основании Устава, с другой стороны,
        далее вместе именуемые «Стороны», заключили настоящий Агентский договор (далее - Договор) о
        нижеследующем:
      </p>

      <h2 style={styles.sectionTitle}>1. ОСНОВНЫЕ ТЕРМИНЫ И ПОНЯТИЯ, ИСПОЛЬЗУЕМЫЕ В ДОГОВОРЕ</h2>
      <p style={styles.paragraph}>В настоящем Договоре используются следующие основные термины и понятия:</p>
      <p style={styles.paragraph}>
          <strong> — Акцепт</strong> – акцептом Поручения считается совершение Агентом двух действий: получение
          Агентом от Принципала Поручения и подписание Поручения Агентом.</p>
          <p style={styles.paragraph}>
          <strong> — Владелец сертификата ключа проверки электронной подписи</strong> - лицо, которому выдан
          сертификат ключа проверки электронной подписи.</p>
          <p style={styles.paragraph}>
          <strong> — Возмещение</strong> – денежная сумма, подлежащая перечислению Принципалом Агенту для исполнения
          запрашиваемого Платежа.</p>

      <h2 style={styles.sectionTitle}>2. ПРЕДМЕТ ДОГОВОРА</h2>
      <p style={styles.paragraph}>
        2.1. Предметом настоящего Договора является предоставление Агентом услуг по сопровождению Контрактов
        Принципала с Поставщиками, включая:
      </p>
      <p style={styles.paragraph}>
      — осуществление Агентом Платежей в пользу Поставщиков на основании Поручений Принципала за Комиссию от
          имени и за счет Принципала;
        </p>
        <p style={styles.paragraph}>
        — ведение Агентом учета денежных требований, в отношении которых Агентом осуществлены Платежи.
        </p>
      <p style={styles.paragraph}>
        Принципал обязуется предоставить Агенту Возмещение и оплачивать Комиссию за Платеж.
      </p>

      <h2 style={styles.sectionTitle}>3. ПОРЯДОК ПРЕДОСТАВЛЕНИЯ ПОРУЧЕНИЯ И СОВЕРШЕНИЯ ПЛАТЕЖА</h2>
      <p style={styles.paragraph}>
        3.1. В отношении Денежных требований, оплату которых Принципал поручает Агенту, Принципал направляет Агенту
        Поручение, подписанное Принципалом (по форме Приложения № 2 к настоящему Договору).
      </p>
      <p style={styles.paragraph}>
        3.2. В дату предоставления Поручения Принципал перечисляет на счет Агента Возмещение, равное сумме Платежа в
        российских рублях или эквивалентное сумме в иностранной валюте.
      </p>
      {/* Продолжить остальные пункты */}

      <h2 style={styles.sectionTitle}>4. ПРАВА, ОБЯЗАННОСТИ И ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
      <h3 style={styles.subSectionTitle}>4.1. Права и обязанности Принципала</h3>
      <p style={styles.paragraph}>
        4.1.1. Принципал обязуется возмещать документально подтверждённые расходы, а также перечислить Возмещение и
        оплатить Комиссию за Платеж Агента.
      </p>
      {/* Остальные пункты раздела */}

      <h2 style={styles.sectionTitle}>5. РАЗМЕР И ПОРЯДОК УПЛАТЫ КОМИССИИ ЗА ПЛАТЕЖ</h2>
      <p style={styles.paragraph}>
        5.1. За выполнение Поручений Принципал обязан уплатить Агенту Комиссию за платеж. Размер Комиссии определяется
        индивидуально для каждого Платежа.
      </p>

      <h2 style={styles.sectionTitle}>6. ЗАВЕРЕНИЯ ОБ ОБСТОЯТЕЛЬСТВАХ</h2>
      <p style={styles.paragraph}>
        Стороны заявляют и гарантируют, что на дату заключения Договора следующие заверения об обстоятельствах являются
        действительными:
      </p>
      <p style={styles.paragraph}>а) Сторона обладает всеми полномочиями для заключения настоящего Договора и исполнения обязательств;</p>
      <p style={styles.paragraph}>б) Сторона предприняла все корпоративные и иные действия, необходимые для заключения и исполнения Договора;</p>
        {/* Добавить остальные заверения */}

      <h2 style={styles.sectionTitle}>9. РЕКВИЗИТЫ И ПОДПИСИ СТОРОН</h2>
      <table style={styles.table}>
    <tbody>
      <tr>
        <td style={styles.signaturesItem}>
          <strong>Агент:</strong>
          <p style={styles.paragraph}>
            ___________________________<br />
            (__________________________)<br />
            Место нахождения: _________________________<br />
            ОГРН _________________,<br />
            ИНН __________________,<br />
            р/с __________________,<br />
            к/с __________________,
          </p>
        </td>
        <td style={styles.signaturesItem}>
          <strong>Принципал:</strong>
          <p style={styles.paragraph}>
            {order.full_company_name}<br />
            Место нахождения: {order.address}<br />
            ОГРН {order.ogrn},<br />
            ИНН {order.inn},<br />
            р/с {order.account_number} в {order.bank_name}<br />
            к/с {order.correspondent_account}, БИК {order.bic},
          </p>
        </td>
      </tr>
    </tbody>
  </table>

  <table style={styles.table}>
    <tbody>
      <tr>
        <td style={styles.signaturesItem}>
          <strong>От имени Агента:</strong>
          <p style={styles.paragraph}>Директор</p>
          <br />
          <p style={styles.paragraph}>________________________/ Мощинскис М. /</p>
        </td>
        <td style={styles.signaturesItem}>
          <strong>От имени Принципала:</strong>
          <p style={styles.paragraph}>{order.executive_position}</p>
          <br />
          <p style={styles.paragraph}>______________________/ {formatToSurnameAndInitials(order.executive_name)} /</p>
        </td>
      </tr>
    </tbody>
  </table>
    </div>
  );
}
