import React, { useState } from "react";
import { Button, Box, Text, VStack } from "@chakra-ui/react";

const CurrencyParser = () => {
  const [rates, setRates] = useState(null);

  const fetchRates = async () => {
    try {
      const response = await fetch(
        "https://www.cbr-xml-daily.ru/daily_json.js"
      );
      const data = await response.json();

      const currencyPairs = {
        "USD - RUB": data.Valute.USD.Value,
        "RUB - USD": 1 / data.Valute.USD.Value,
        "EUR - RUB": data.Valute.EUR.Value,
        "RUB - EUR": 1 / data.Valute.EUR.Value,
        "CNY - RUB": data.Valute.CNY.Value,
        "RUB - CNY": 1 / data.Valute.CNY.Value,
        "AED - RUB": data.Valute.AED ? data.Valute.AED.Value : null,
        "RUB - AED": data.Valute.AED ? 1 / data.Valute.AED.Value : null,
        "CNH - RUB": data.Valute.CNH ? data.Valute.CNH.Value : null,
        "RUB - CNH": data.Valute.CNH ? 1 / data.Valute.CNH.Value : null,
      };

      setRates(currencyPairs);
      console.log("Currency Rates:", currencyPairs);
    } catch (error) {
      console.error("Error fetching currency rates:", error);
    }
  };

  return (
    <Box p={4}>
      <Button colorScheme="blue" onClick={fetchRates} mb={4}>
        Получить курсы валют
      </Button>

      {rates && (
        <VStack align="start" spacing={3}>
          {Object.entries(rates).map(([pair, rate]) => (
            <Text key={pair}>
              {pair}: {rate ? rate.toFixed(4) : "N/A"}
            </Text>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default CurrencyParser;
