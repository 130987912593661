import React, { useState, useMemo, useEffect, useContext } from 'react';
import {
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Spinner,
  useColorModeValue,
  Tooltip,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdCallMade, MdCallReceived } from 'react-icons/md';
import Card from 'components/card/Card';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserContext } from "contexts/UserContext";
import { AiOutlineFileText, AiOutlineClockCircle, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineFileAdd } from 'react-icons/ai';

export default function ComplexTable({ tableData }) {
  const { user } = useContext(UserContext);
  const [sorting, setSorting] = useState({ field: 'datum', direction: 'desc' });
  const [visibleData, setVisibleData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false); // Новое состояние для управления Spinner
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/admin/orders/${id}`);
  };

  const sortedData = useMemo(() => {
    const sorted = [...tableData].sort((a, b) => {
      const aValue = a[sorting.field];
      const bValue = b[sorting.field];

      // Универсальная сортировка по строкам, числам и датам
      if (typeof aValue === 'string' || typeof bValue === 'string') {
        return sorting.direction === 'asc' 
          ? aValue.toString().localeCompare(bValue.toString()) 
          : bValue.toString().localeCompare(aValue.toString());
      } else if (aValue instanceof Date || bValue instanceof Date) {
        return sorting.direction === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
      } else {
        return sorting.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }
    });
    return sorted;
  }, [sorting, tableData]);

  const fetchMoreData = () => {
    if (visibleData.length >= sortedData.length) {
      setHasMore(false);
      return;
    }

    setLoading(true); // Включаем Spinner перед началом загрузки
    setTimeout(() => {
      const moreData = sortedData.slice(
        visibleData.length,
        visibleData.length + 10
      );
      setVisibleData((prevData) => [...prevData, ...moreData]);
      setLoading(false); // Выключаем Spinner после завершения загрузки
    }, 1000);
  };

  useEffect(() => {
    setVisibleData(sortedData.slice(0, 20));
    setHasMore(true);
  }, [sortedData]);

  const handleSort = (field) => {
    setSorting((prevSorting) => ({
      field,
      direction: prevSorting.field === field ? (prevSorting.direction === 'asc' ? 'desc' : 'asc') : 'asc',
    }));
  };

  const navigateToStatus = (status) => {
    navigate(`/admin/orders/${status}`);
  };
 
  const formatDate = (dateString) => {
    if (!dateString) return ''; // Возвращаем пустую строку, если дата отсутствует
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  if (!tableData) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Text>Загрузка...</Text>
      </Flex>
    );
  }

  return (
    <Card flexDirection="column" w="100%" p={{ base: "20px", md: "40px" }} overflowX={{ sm: 'scroll', lg: 'hidden' }}>
              <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Общее число заявок: { tableData.length }
        </Text>
      <Flex mb={4} justify="flex-end" gap={2}>
      {user?.clientType === "agent" && (
          <Button leftIcon={<AiOutlineFileAdd />} colorScheme="gray" variant="outline" size="sm" fontSize="12px" onClick={() => navigateToStatus("new")}>
            Новые заявки
          </Button>
        )}
        <Button leftIcon={<AiOutlineClockCircle />} colorScheme="gray" variant="outline" size="sm" fontSize="12px" onClick={() => navigateToStatus("client")}>
          Ожидают ваших действий
        </Button>
        <Button leftIcon={<AiOutlineClockCircle />} colorScheme="gray" variant="outline" size="sm" fontSize="12px" onClick={() => navigateToStatus("agent")}>
          Ожидают действия агента
        </Button>
        <Button leftIcon={<AiOutlineFileText />} colorScheme="gray" variant="outline" size="sm" fontSize="12px" onClick={() => navigateToStatus("drafts")}>
          Черновики
        </Button>
        <Button leftIcon={<AiOutlineCheckCircle />} colorScheme="gray" variant="outline" size="sm" fontSize="12px" onClick={() => navigateToStatus("completed")}>
          Выполненные
        </Button>
        <Button leftIcon={<AiOutlineCloseCircle />} colorScheme="gray" variant="outline" size="sm" fontSize="12px" onClick={() => navigateToStatus("canceled")}>
          Отмененные
        </Button>
      </Flex>

      <Box>
        <InfiniteScroll
          dataLength={visibleData.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={loading && (
            <Flex justify="center" align="center" p={4}>
              <Spinner size="lg" color="gray.300" />
            </Flex>
          )}
          endMessage={<p style={{ textAlign: 'center' }}>Все данные загружены</p>}
          height={600}
        >

         
          <Table variant="striped" color="gray.500" mb="24px" mt="12px">
            <Thead>
              <Tr>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('type')} cursor="pointer">Тип</Th>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('datum')} cursor="pointer">Дата подачи заявки</Th>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('partnerBankCountry')} cursor="pointer">Страна Получателя денег</Th>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('partnerName')} cursor="pointer">Получатель денег</Th>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('paymentAmount')} cursor="pointer">Сумма</Th>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('currency')} cursor="pointer">Валюта</Th>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('senderCountry')} cursor="pointer">Страна Отправителя денег</Th>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('senderName')} cursor="pointer">Отправитель денег</Th>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('status')} cursor="pointer">Статус</Th>
                <Th fontSize="10px" textAlign="center" lineHeight="105%" onClick={() => handleSort('executionDate')} cursor="pointer">Дата исполнения заявки</Th>
              </Tr>
            </Thead>
            <Tbody>
  {visibleData.map((row) => {
    // Заменяем null на пустую строку
    const sanitizedRow = Object.fromEntries(
      Object.entries(row).map(([key, value]) => [key, value ?? ''])
    );

    return (
      <Tr
        key={sanitizedRow.id}
        cursor="pointer"
        _hover={{ backgroundColor: 'gray.200' }}
        onClick={() => handleRowClick(sanitizedRow.id)}
      >
        <Td>
          <Flex align="center">
            {sanitizedRow.type === 'export' ? (
              <Tooltip label="Получаю деньги (Экспортер)" fontSize="xs" placement="top" hasArrow>
                <span>
                  <Icon as={MdCallReceived} w={5} h={5} color="green.500" />
                </span>
              </Tooltip>
            ) : sanitizedRow.type === 'import' ? (
              <Tooltip label="Отправляю деньги (Импортер)" fontSize="xs" placement="top" hasArrow>
                <span>
                  <Icon as={MdCallMade} w={5} h={5} color="blue.500" />
                </span>
              </Tooltip>
            ) : null}
          </Flex>
        </Td>
        <Td textAlign="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatDate(sanitizedRow.datum)}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {sanitizedRow.type === 'import' ? sanitizedRow.partner_bank_country : 'Россия'}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {sanitizedRow.type === 'import' ? sanitizedRow.partner_name : sanitizedRow.short_company_name}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {sanitizedRow.payment_amount}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {sanitizedRow.currency}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {sanitizedRow.type === 'import' ? 'Россия' : sanitizedRow.partner_bank_country}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {sanitizedRow.type === 'import' ? sanitizedRow.short_company_name : sanitizedRow.partner_name}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {sanitizedRow.status}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {formatDate(sanitizedRow.execution_date)}
          </Text>
        </Td>
      </Tr>
    );
  })}
</Tbody>

          </Table>
        </InfiniteScroll>
      </Box>
    </Card>
  );
}
