import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.jpg";
import { RiUser3Line, RiStoreLine } from "react-icons/ri";
import { UserContext } from "contexts/UserContext"; // Импорт UserContext

function Register() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const { register } = useContext(UserContext); // Используем функцию регистрации из контекста

  const [clientType, setClientType] = useState("client"); // По умолчанию "Клиент"
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [INN, setINN] = useState("");
  const [agentId, setAgentId] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Для отображения прелоадера

  // Обработчик отправки формы
  const handleRegister = async (e) => {
    e.preventDefault(); // Отключение стандартного поведения формы
    setIsLoading(true); // Включаем прелоадер
    try {
      await register(email, clientType, INN, agentId, name); // Вызываем функцию регистрации
    } catch (error) {
      console.error("Ошибка регистрации:", error);
    } finally {
      setIsLoading(false); // Выключаем прелоадер
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Зарегистрироваться
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Выберите ваш логин и пароль чтобы войти на платформу.
          </Text>
        </Box>

        {/* Кнопки выбора клиента */}
        <Flex mb="24px" justifyContent="space-between" w="100%">
          <Button
            leftIcon={<RiUser3Line />}
            w="49%"
            colorScheme={clientType === "client" ? "blue" : "gray"}
            onClick={() => setClientType("client")}
          >
            Я Клиент
          </Button>
          <Button
            leftIcon={<RiStoreLine />}
            w="49%"
            colorScheme={clientType === "agent" ? "blue" : "gray"}
            onClick={() => setClientType("agent")}
          >
            Я Агент
          </Button>
        </Flex>

        {/* Форма регистрации */}
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleRegister}>
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Введите Фамилию и Имя
              </FormLabel>
              <Input
                isRequired
                fontSize="sm"
                placeholder="Иванов Иван Иванович"
                mb="24px"
                size="lg"
                variant="auth"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Введите ваш Email
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                fontSize="sm"
                type="email"
                placeholder="mail@nppu.ru"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Введите ИНН организации
              </FormLabel>
              <Input
                isRequired
                type="number" // Убрана проверка на ввод цифр
                fontSize="sm"
                placeholder="Введите ИНН"
                mb="24px"
                size="lg"
                variant="auth"
                value={INN}
                onChange={(e) => setINN(e.target.value)}
              />

              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Код агента (необязательно)
              </FormLabel>
              <Input
                type="number" // Тип - текстовый
                fontSize="sm"
                placeholder="12345"
                mb="24px"
                size="lg"
                variant="auth"
                value={agentId}
                onChange={(e) => setAgentId(e.target.value)}
              />

              <Button
                type="submit" // Отправка формы по Enter
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                isLoading={isLoading} // Прелоадер во время загрузки
                loadingText="Отправка..." // Текст во время загрузки
              >
                Зарегистрироваться
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Register;
