import React, { useState} from 'react';
import {
  Button,
  Box,
  Stepper,
  Select,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepNumber,
  StepDescription,
  StepSeparator,
  useSteps,
  Text,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
  Grid,
  Input,
  Icon
} from '@chakra-ui/react';
import { MdCheckCircle, MdCallMade, MdCallReceived } from "react-icons/md"; // Подключаем иконки из react-icons
import FileUpload from "views/admin/profile/components/Upload";
const stepsData = [
  { title: 'Экспорт/Импорт', description: 'Выберите операцию' },
  { title: 'Детали заявки', description: 'Заполните форму' }
];


const RequestFormSteps = ({ formData, handleSubmit, handleFormUpdate }) => {

  const [type, settype] = useState(''); // Состояние для хранения выбранной операции (импорт/экспорт)

  const { activeStep, setActiveStep } = useSteps({
    initialStep: 0,
  });


  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const prevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  
  
 


  // Функция обработки выбора операции
  const handleOperationSelect = (type) => {
    settype(type); // Устанавливаем тип операции
    handleFileUpload( "type", type );
    nextStep(); // Переход к следующему шагу
  };

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const [contractFileName, setContractFileName] = useState(null);
  const [isContractUploaded, setIsContractUploaded] = useState(false);
  const [invoiceFileName, setInvoiceFileName] = useState(null);
  const [isInvoiceUploaded, setIsInvoiceUploaded] = useState(false);
  const [companyCardFileName, setCompanyCardFileName] = useState("");
  const [isCompanyCardUploaded, setIsCompanyCardUploaded] = useState(false);
  const [specializationFileName, setSpecializationFileName] = useState("");
  const [isSpecializationUploaded, setIsSpecializationUploaded] = useState(false);
  const [russiaCompanyCardFileName, setRussiaCompanyCardFileName] = useState("");
  const [isRussiaCompanyCardUploaded, setIsRussiaCompanyCardUploaded] = useState(false);

  const isFilesUploaded =
  type === "import"
    ? isContractUploaded && isInvoiceUploaded
    : type === "export"
    ? isContractUploaded && isInvoiceUploaded && isCompanyCardUploaded && isSpecializationUploaded && isRussiaCompanyCardUploaded
    : false;

    // Функция обработки загрузки файлов для контракта
    const handleContractUploadComplete = (fieldName, fileName) => {
      setContractFileName(fileName); // Устанавливаем имя файла
      handleFileUpload(fieldName, fileName);
      setIsContractUploaded(true); // Обновляем состояние загрузки
    };


    const handleInvoiceUploadComplete = (fieldName, fileName) => {
      setInvoiceFileName(fileName); // Устанавливаем название загруженного файла
      handleFileUpload(fieldName, fileName);
      setIsInvoiceUploaded(true); // Обновляем состояние, что файл загружен
    };

    const handleCompanyCardUploadComplete = (fieldName, fileName) => {
      setCompanyCardFileName(fileName); // Устанавливаем название загруженного файла
      handleFileUpload(fieldName, fileName);
      setIsCompanyCardUploaded(true); // Обновляем состояние, что файл загружен
    };
    
    const handleSpecializationUploadComplete = (fieldName, fileName) => {
      setSpecializationFileName(fileName); // Устанавливаем название загруженного файла
      handleFileUpload(fieldName, fileName);
      setIsSpecializationUploaded(true); // Обновляем состояние, что файл загружен
    };

    const handleRussiaCompanyCardUploadComplete = (fieldName, fileName) => {
      setRussiaCompanyCardFileName(fileName); // Устанавливаем название загруженного файла
      handleFileUpload(fieldName, fileName);
      setIsRussiaCompanyCardUploaded(true); // Обновляем состояние, что файл загружен
    };
    
    const handleFieldChange = (e) => {
      const { name, value } = e.target;
      handleFormUpdate({ [name]: value }); // Обновляем конкретное поле
    };

    const handleFileUpload = (name, value) => {
      handleFormUpdate({ [name]: value }); // Обновляем конкретное поле
    };


  


  return (
    <>
      {/* Stepper */}
      <Box p={4}>
        <Stepper index={activeStep} colorScheme="blue" maxW="60%" ml="auto" mr="auto" mb={5}>
          {stepsData.map((stepData, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<MdCheckCircle />} 
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{stepData.title}</StepTitle>
                <StepDescription>{stepData.description}</StepDescription>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Содержание шагов */}
      <Box>
        {/* Шаг 1: Выбор типа клиента */}
        {activeStep === 0 && (
           
          <Box>
            <Text fontSize="xl" mb={5} textAlign="center" fontWeight="bold">
  Выберите тип заявки
</Text>
            <Flex justify="center" align="center" gap={4} mb={4}>
              <Button
                size="lg"
                variant='outline'
                width="250px"
                height="250px"
                bg="gray.50"
                _hover={{ bg: "white" }} 
                flexDirection="column"
                onClick={() => handleOperationSelect('import')} // Выбор "Импорт"
              >
                <Icon as={MdCallMade} w={12} h={12} mb={2} color="blue.500" />
                <Text fontSize="md" mb={2}>Импорт</Text>
                <Text fontSize="sm" color="gray.500">Мне надо оплатить</Text>
              </Button>

              <Button
                size="lg"
                variant='outline'
                width="250px"
                height="250px"
                bg="gray.50"
                _hover={{ bg: "white" }} 
                flexDirection="column"
                onClick={() => handleOperationSelect('export')} // Выбор "Экспорт"
              >
                <Icon as={MdCallReceived} w={12} h={12} mb={2} color="green.500" />
                <Text fontSize="md" mb={2}>Экспорт</Text>
                <Text fontSize="sm" color="gray.500">Мне надо вернуть</Text>
                <Text fontSize="sm" color="gray.500">валютную выручку</Text>
              </Button>
            </Flex>
          </Box>
        )}

        {/* Шаг 2: Импорт */}
        {activeStep === 1 && (
          <>
          <Text fontSize="xl" mb={7} textAlign="center" fontWeight="bold">
          Загрузите документы и заполните детали заявки
        </Text>
          <Flex justifyContent="space-between" alignItems="flex-start">
{/* Левая часть: Поля */}


<Flex direction="column" flex="1" pe="44px" w="100%">
<Box mb={8}>
      <Flex direction="column" align="center">
        <Box textAlign="center" p={4} bg="white">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="md">
            Загрузите Договор
          </Text>
          <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2}>
            Contract
          </Text>

          <Flex alignItems="center" justify="center" mb={3}>
            {contractFileName ? (
              <>
                <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
                <Text color={textColorPrimary} fontSize="sm">
                  {contractFileName}
                </Text>
              </>
            ) : (
              <Text color={textColorSecondary} fontSize="sm">
                Загрузите, пожалуйста, электронные копии документов
              </Text>
            )}
          </Flex>

          {/* Интеграция FileUpload */}
          <FileUpload
            setIsFileUploaded={setIsContractUploaded} // Обновление состояния загрузки
            uploadType="contracts" // Указываем тип загрузки
            onFileUploaded={(contractFileName) => handleContractUploadComplete("contract_name", contractFileName)}
          />
        </Box>
      </Flex>
    </Box>

      {/* Загрузка счёта */}
      <Box mb={8}>
  <Flex direction="column" align="center">
    <Box textAlign="center" p={4} bg="white">
      <Text color={textColorPrimary} fontWeight="bold" fontSize="md">
        Загрузите Счёт
      </Text>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2}>
        Invoice
      </Text>

      <Flex alignItems="center" justify="center" mb={3}>
        {invoiceFileName ? (
          <>
            <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
            <Text color={textColorPrimary} fontSize="sm">
              {invoiceFileName}
            </Text>
          </>
        ) : (
          <Text color={textColorSecondary} fontSize="sm">
            Загрузите, пожалуйста, электронные копии документов
          </Text>
        )}
      </Flex>

      {/* Интеграция FileUpload */}
      <FileUpload
        setIsFileUploaded={setIsInvoiceUploaded} // Обновление состояния загрузки
        uploadType="invoices" // Указываем тип загрузки
        onFileUploaded={(invoiceFileName) => handleInvoiceUploadComplete("invoice_name", invoiceFileName)}
      />
    </Box>
  </Flex>
</Box>


  {type === 'export' && (
<>
<Box mb={8}>
  <Flex direction="column" align="center">
    <Box textAlign="center" p={4} bg="white">
      <Text color={textColorPrimary} fontWeight="bold" fontSize="md">
        Карточка компании
      </Text>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2}>
        Company card
      </Text>
      
      <Flex alignItems="center" justify="center" mb={3}>
        {companyCardFileName ? (
          <>
            <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
            <Text color={textColorPrimary} fontSize="sm">
              {companyCardFileName}
            </Text>
          </>
        ) : (
          <Text color={textColorSecondary} fontSize="sm">
            Загрузите, пожалуйста, электронные копии документов
          </Text>
        )}
      </Flex>

      {/* Интеграция FileUpload */}
      <FileUpload
        setIsFileUploaded={setIsCompanyCardUploaded} // Обновление состояния загрузки
        uploadType="card" // Указываем тип загрузки
        onFileUploaded={(cardFileName) => handleCompanyCardUploadComplete("card_name", cardFileName)}
      />
    </Box>
  </Flex>
</Box>


      {/* Сведения о специализации компании */}
      <Box mb={8}>
  <Flex direction="column" align="center">
    <Box textAlign="center" p={4} bg="white">
      <Text color={textColorPrimary} fontWeight="bold" fontSize="md">
        Специализация
      </Text>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2}>
        Specialization
      </Text>

      <Flex alignItems="center" justify="center" mb={3}>
        {specializationFileName ? (
          <>
            <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
            <Text color={textColorPrimary} fontSize="sm">
              {specializationFileName}
            </Text>
          </>
        ) : (
          <Text color={textColorSecondary} fontSize="sm">
            Загрузите, пожалуйста, электронные копии документов
          </Text>
        )}
      </Flex>

      {/* Интеграция FileUpload */}
      <FileUpload
        setIsFileUploaded={setIsSpecializationUploaded} // Обновление состояния загрузки
        uploadType="specialization" // Указываем тип загрузки
        onFileUploaded={(specializationFileName) => handleSpecializationUploadComplete("specialization_name", specializationFileName)}
      />
    </Box>
  </Flex>
</Box>


      {/* Карточка компании, на которую необходимо заплатить в РФ */}
      <Box mb={8}>
  <Flex direction="column" align="center">
    <Box textAlign="center" p={4} bg="white">
      <Text color={textColorPrimary} fontWeight="bold" fontSize="md">
        Карточка компании, на которую необходимо заплатить в РФ
      </Text>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2}>
        Russia company card
      </Text>

      <Flex alignItems="center" justify="center" mb={3}>
        {russiaCompanyCardFileName ? (
          <>
            <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
            <Text color={textColorPrimary} fontSize="sm">
              {russiaCompanyCardFileName}
            </Text>
          </>
        ) : (
          <Text color={textColorSecondary} fontSize="sm">
            Загрузите, пожалуйста, электронные копии документов
          </Text>
        )}
      </Flex>

      {/* Интеграция FileUpload */}
      <FileUpload
        setIsFileUploaded={setIsRussiaCompanyCardUploaded} // Обновление состояния загрузки
        uploadType="ru_card" // Указываем тип загрузки
        onFileUploaded={(rucardFileName) => handleRussiaCompanyCardUploadComplete("rucard_name", rucardFileName)} // Вызываем при успешной загрузке
      />
    </Box>
  </Flex>
</Box>

   </>
  )}

      {/* Кнопка отправки */}
      <Button
        isDisabled={!isFilesUploaded}
        colorScheme="blue"
        width="100%"
        onClick={handleSubmit}
      >
        <Flex direction="column" align="center">
          <Text fontSize="12">Не хочу заполнять другие поля</Text>
          <Text fontSize="14">Отправить в работу</Text>
        </Flex>
      </Button>
    </Flex>

{/* Правая часть: Поля */}
<Box flex="3">
<Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
<Box p={4} bg="white" borderRadius="md">
<Text fontSize="md" fontWeight="bold" mb={4}>
{type === 'import' ? 'Банк Получателя / Beneficiary Bank' : 'Банк Отправителя / Sender Bank'}
</Text>
<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Наименование Банка Получателя' : 'Наименование Банка Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{type === 'import' ? 'Beneficiary Bank Name' : 'Sender Bank Name'}
</Text>
</FormLabel>
<Input
type="text"
name="partner_bank_name"
value={formData.partner_bank_name}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Страна Банка Получателя' : 'Страна Банка Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{type === 'import' ? 'Beneficiary Bank Country' : 'Sender Bank Country'}
</Text>
</FormLabel>
<Input
type="text"
name="partner_bank_country"
value={formData.partner_bank_country}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Адрес Банка Получателя' : 'Адрес Банка Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{type === 'import' ? 'Beneficiary Bank Address' : 'Sender Bank Address'}
</Text>
</FormLabel>
<Input
type="text"
name="partner_bank_address"
value={formData.partner_bank_address}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Код Банка Получателя (SWIFT)' : 'Код Банка Отправителя (SWIFT)'}
 </Text>
<Text fontSize="xs" color="gray.500">
  SWIFT ID# of Foreign Bank
</Text>
</FormLabel>
<Input
type="text"
name="partner_bank_swift"
value={formData.partner_bank_swift}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>

</Box>

{/* Блок: Получатель */}
<Box p={4} bg="white" borderRadius="md">
<Text fontSize="md" fontWeight="bold" mb={4}>
{type === 'import' ? 'Получатель / Beneficiary' : 'Отправитель / Sender'}
</Text>
<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Наименование Получателя' : 'Наименование Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{type === 'import' ? 'Beneficiary Name on Account' : 'Sender Name on Account'}
</Text>
</FormLabel>
<Input
type="text"
name="partner_name"
value={formData.partner_name}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Адрес Получателя' : 'Адрес Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{type === 'import' ? 'Beneficiary Address' : 'Sender Address'}
</Text>
</FormLabel>
<Input
type="text"
name="partner_address"
value={formData.partner_address}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Счет Получателя' : 'Счет Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{type === 'import' ? 'Beneficiary Bank Account# or IBAN#' : 'Sender Bank Account# or IBAN#'}
</Text>
</FormLabel>
<Input
type="text"
name="partner_account"
value={formData.partner_account}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Веб-сайт получателя' : 'Веб-сайт отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
Website
</Text>
</FormLabel>
<Input
type="url"
name="partner_website"
value={formData.partner_website}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>
</Box>

{/* Блок: Контракт и счёт */}
<Box p={4} bg="white" borderRadius="md">
<Text fontSize="md" fontWeight="bold" mb={4}>Контракт и счёт / Contract and Invoice</Text>
<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">Описание товара/услуги</Text>
<Text fontSize="xs" color="gray.500">Product/service description</Text>
</FormLabel>
<Input
type="text"
name="product_description"
value={formData.product_description}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">Номер инвойса</Text>
<Text fontSize="xs" color="gray.500">No of invoice</Text>
</FormLabel>
<Input
type="text"
name="invoice_number"
value={formData.invoice_number}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">Дата инвойса</Text>
<Text fontSize="xs" color="gray.500">Date of invoice</Text>
</FormLabel>
<Input
type="date"
name="invoice_date"
defaultValue="" 
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>

<Box>
<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Номер контракта c поставщиком' : 'Номер контракта c покупателем'}
 </Text>
<Text fontSize="xs" color="gray.500">
{type === 'import' ? 'No of contract with supplier' : 'No of contract with buyer'}
</Text>
</FormLabel>
<Input
type="text"
name="contract_number"
value={formData.contract_number}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{type === 'import' ? 'Дата контракта c поставщиком' : 'Дата контракта c покупателем'}
 </Text>
<Text fontSize="xs" color="gray.500">
{type === 'import' ? 'Date of contract with supplier' : 'Date of contract with buyer'}
</Text>
</FormLabel>
<Input
type="date"
name="contract_date"
defaultValue="" 
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">Код ТНВД (необязательно)</Text>
<Text fontSize="xs" color="gray.500">TNVD Code</Text>
</FormLabel>
<Input
type="text"
name="tnvd_code"
value={formData.tnvd_code}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>
</Box>

{/* Блок: Платеж */}
<Box p={4} bg="white" borderRadius="md">
<Text fontSize="md" fontWeight="bold" mb={4}>Платеж / Payment</Text>
<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">Назначение платежа</Text>
<Text fontSize="xs" color="gray.500">Details of payment</Text>
</FormLabel>
<Input
type="text"
name="payment_purpose"
value={formData.payment_purpose}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">Сумма платежа</Text>
<Text fontSize="xs" color="gray.500">Amount of payment</Text>
</FormLabel>
<Input
type="number"
name="payment_amount"
value={formData.payment_amount}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>

<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">Валюта</Text>
<Text fontSize="xs" color="gray.500">Currency</Text>
</FormLabel>
<Select
name="currency"
value={formData.currency}
onChange={handleFieldChange}
isDisabled={!isFilesUploaded}
>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="RUB">RUB</option>
                <option value="CNY">CNY</option>
                <option value="AED">AED</option>
                <option value="CNH">CNH</option>
</Select>
</FormControl>
</Box>
</Box>
</Grid>

<Box>

</Box>

</Box>



</Flex>
</>
        )}
</Box>

      <Box display="flex" justifyContent="space-between" mt={6}>
  {activeStep > 0 && (
    <>
    <Button variant="outline" onClick={prevStep}>
      Назад
    </Button>

  <Button         colorScheme="blue"
        size="lg"
        fontSize="15px" isDisabled={!isFilesUploaded} onClick={() => { handleSubmit() }}>
    Отправить заявку
  </Button>
  </>
    )}
</Box>

    </>
  );
};

export default RequestFormSteps;

